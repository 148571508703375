export enum TaskState {
    CREATED = 'Created',
    REOPENED = 'Reopened',
    SET_PLANNED_DATE = 'SetPlannedDate',
    ASSIGNED = 'Assigned',
    COMMENTED = 'Commented',
    FINISHED = 'Finished',
    DENIED = 'Denied',
}

export interface CreateTask {
    id: number;
    parentAnswerId: number;
    solverNetId: string;
    plannedActionDescription: string;
    plannedDate: string;
    actionSolvedDescription: string;
    actionSolvedDate: string;

    [key: string]: string | number | null;
}
export interface AssignTask {
    id: number;
    netId: string;
    comment: string;
    [key: string]: string | number | null;
}

export const assignTaskEmpty: AssignTask = {
    id: 0,
    netId: '',
    comment: '',
};

export interface CommentTask {
    id: number;
    comment: string;
    [key: string]: string | number | null;
}
export const commentTaskEmpty: CommentTask = {
    id: 0,
    comment: '',
};

export interface FinishTask {
    id: number;
    comment: string;
    [key: string]: string | number | null;
}

export const finishTaskEmpty: FinishTask = {
    id: 0,
    comment: '',
};

export const setPlannedDateEmpty = {
    id: 0,
    date: '',
    comment: '',
};

export const reopenTaskEmpty: FinishTask = {
    id: 0,
    comment: '',
};

export interface Solver {
    netId: string;
    name: string;
}

export interface Task extends CreateTask {
    actionSolvedDate: string;
    actionSolvedDescription: string;
    auditType: string;
    customId: number;
    id: number;
    parentAnswerId: number;
    parentAnswer: any;
    plannedActionDescription: string;
    plannedDate: string;
    productionId: number;
    productionName: string;
    solverName: string;
    solverNetId: string;
    state: string;
    vsmId: number;
    vsmName: string;
    zoneName: string;
    zoneBulding: string;
    zoneFloor: string;
    safetyImpact: string;
    // @ts-ignore
    auditTypeDangerousFindings: boolean;
    [key: string]: string | number | null;
}

export type TasksApiResponse = Array<Task>;
