import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { Alert, Button } from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import { cFieldNames, validationSchema } from './schema';
import { useStyles } from '../../pages/AuditSurvey/Common/styles';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import ClearIcon from '@material-ui/icons/Clear';
import { appPaths } from '../../paths';
import { useHistory } from 'react-router';
import { useAuditBtnPos } from '../../hooks/useAuditBtnPos';
import { Zone } from '../../types/ZoneApiResponse';
import { GembaType, GembaTypeTranslated } from '../../pages/AuditSurvey/Layout_4/AuditLayout_4';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from '../../contexts/translationContext';
import { Content } from '../../pages/AuditSurvey/Common/Layout/Content';
import { Footer } from '../../pages/AuditSurvey/Common/Layout/Footer';
import { Header } from '../../pages/AuditSurvey/Common/Layout/Header';
import { AlertDialog } from '../../components/AlertDialog';
import { Answer } from '../../types/ChecklistAnswerApiResponse';

interface Props {
    onSubmit: (auditId: number, isDangerous: boolean | null, comment?: string) => void;
    handleCreateAttachments: any;
    takingPicture: boolean;
    setTakingPicture: any;
    zoneList: Zone[];
    gembaType: GembaType;
    setCheckListFinished: any;
    counter: number;
    setCounter: any;
    answers: Answer[];
    dangerousFindings: boolean;
}

const initialValues: any = {
    comment: '',
};

export const QuestionFormL4 = ({
    onSubmit,
    handleCreateAttachments,
    takingPicture,
    setTakingPicture,
    zoneList,
    gembaType,
    setCheckListFinished,
    counter,
    setCounter,
    answers,
    dangerousFindings,
}: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [photoRequiredError, setPhotoRequiredError] = useState<boolean>();
    const [commentRequiredError, setCommentRequiredError] = useState<boolean>();
    const [imageCapture, setImageCapture] = useState<any[]>([]);
    const [selectedZoneId, setSelectedZoneId] = useState<number>(zoneList[0]?.id);
    const [openAlertStorno, setOpenAlertStorno] = useState<boolean>(false);
    const [openAlertFinish, setOpenAlertFinish] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isFinishing, setIsFinishing] = useState<boolean>(false);
    const [isStorno, setIsStorno] = useState<boolean>(false);
    const [isDangerous, setIsDangerous] = useState<boolean>(false);
    const [tmpLastComment, setTmpLastComment] = useState<string>('');

    // File upload
    const [uploadingFiles, setUploadingFiles] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);

    // States pro nastavení pozice buttonů
    const lastButtonRef = useRef<any>(null);
    const { buttonsContainerStyle, setButtonsContainerStyle, setCalcButtonsPos } = useAuditBtnPos({
        lastElementRef: lastButtonRef,
    });

    const handleTakePhoto = (dataUri) => {
        setImageCapture([dataUri, ...imageCapture]);
    };

    useEffect(() => {
        if (imageCapture.length > 0) setPhotoRequiredError(false);
    }, [imageCapture]);

    const handleFormSubmit = (formData) => {
        if (isFinishing) {
            if (imageCapture.length > 0 && formData.comment !== '') {
                setTmpLastComment(formData.comment);
                finishChecklist(true);
            } else if (imageCapture.length > 0 || formData.comment !== '') {
                if (imageCapture.length > 0)
                    setErrorMessage(t('Audit.layout.L4.modal.comment-required'));
                if (formData.comment !== '')
                    setErrorMessage(t('Audit.layout.L4.modal.photo-required'));
                setOpenAlertFinish(true);
            } else {
                setCheckListFinished(true);
            }
            setIsFinishing(false);
        } else if (isStorno) {
            if (imageCapture.length > 0 || formData.comment !== '' || answers.length > 0) {
                setOpenAlertStorno(true);
                setIsStorno(false);
            } else {
                history.push(appPaths.AUDIT);
            }
        } else {
            let isError = false;
            if (imageCapture.length < 1) {
                isError = true;
                setPhotoRequiredError(true);
            }
            // Komentář je povinný
            if (formData.comment === '') {
                isError = true;
                setCommentRequiredError(true);
            }
            if (isError) return;
            let tmpAudit: any = zoneList.find((zone: any) => zone.id === selectedZoneId);
            // Validace je ok. Nastavíme přílohy, potvrdíme.
            if (selectedFiles)
                handleCreateAttachments(imageCapture, tmpAudit.auditId, selectedFiles);
            else handleCreateAttachments(imageCapture, tmpAudit.auditId);

            onSubmit(tmpAudit.auditId, dangerousFindings ? isDangerous : null, formData.comment);
            if (formData.comment) formData.comment = '';
            setImageCapture([]);
            setSelectedFiles([]);
            setTmpLastComment('');
            setTakingPicture(true);
            NotificationManager.success(t('Audit.layout.L4.notify.ready-for-upload'));
            setCounter(counter + 1);
        }
    };

    const getGembaName = (gembaType: string): string => {
        if (gembaType === 'Weekly') return GembaTypeTranslated.Weekly;
        if (gembaType === 'Extraordinary') return GembaTypeTranslated.Extraordinary;
        return '';
    };

    const finishChecklist = (savePhotos: boolean) => {
        if (savePhotos) {
            // save photos
            let tmpAudit: any = zoneList.find((zone: any) => zone.id === selectedZoneId);
            if (selectedFiles)
                handleCreateAttachments(imageCapture, tmpAudit.auditId, selectedFiles);
            else handleCreateAttachments(imageCapture, tmpAudit.auditId);

            onSubmit(tmpAudit.auditId, isDangerous, tmpLastComment);
            setTmpLastComment('');
            setImageCapture([]);
            setSelectedFiles([]);
        }
        // finishChecklist
        setCheckListFinished(true);
    };

    // reload kamery - po vypnutí browseru telefonu a znovu zapnutí je občas černá obrazovka
    const reloadCamera = () => {
        setTakingPicture(false);
        setTimeout(function () {
            setTakingPicture(true);
        }, 500);
    };

    // Při načtení otázky nastaví pozici spodních buttonů na automatickou tzn. zařadí se před předchozí obsah
    // Nastaví se příznak, že se má přepočítat pozice buttonů
    useEffect(() => {
        setButtonsContainerStyle({});
        setTimeout(() => {
            setCalcButtonsPos(true);
        }, 10);
    }, [photoRequiredError, commentRequiredError, imageCapture]);

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (!event.target.files) return;

        // @ts-ignore
        if (selectedFiles) setSelectedFiles([...selectedFiles, ...event.target.files]);
        // @ts-ignore
        else setSelectedFiles([...event.target.files]);

        setUploadingFiles(false);
    };

    return (
        <>
            <Header paddingTop={15} paddingBottom={15}>
                <Typography variant="h1" align="center" className={classes.labelText}>
                    {t('Audit.layout.L4.new-photos')} - {t(getGembaName(gembaType))}:
                </Typography>
            </Header>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t)}
                onSubmit={handleFormSubmit}
            >
                {({ handleChange, handleSubmit }) => (
                    <Form onSubmit={handleSubmit} onChange={handleChange}>
                        <Content bottomOffset={60}>
                            <Grid
                                container
                                alignContent={'center'}
                                alignItems={'center'}
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                <Grid item xs={12}>
                                    {!uploadingFiles && (
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Typography
                                                    align="center"
                                                    className={classes.labelText}
                                                    style={{ marginTop: '5px' }}
                                                >
                                                    {t('Audit.layout.L4.label-zone')}
                                                </Typography>
                                            </Grid>
                                            {selectedZoneId && (
                                                <Grid item xs={8}>
                                                    <Select
                                                        id="select"
                                                        label={t('Audit.layout.L4.label-zone')}
                                                        value={selectedZoneId}
                                                        className={classes.formSelect}
                                                        style={{ width: '100%' }}
                                                        onChange={(e) =>
                                                            setSelectedZoneId(e.target.value as any)
                                                        }
                                                    >
                                                        {zoneList.map(
                                                            (zone: Zone, index: number) => (
                                                                <MenuItem
                                                                    key={index}
                                                                    value={zone.id}
                                                                >
                                                                    {zone.name}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </Grid>
                                            )}
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    {takingPicture && !uploadingFiles && (
                                        <>
                                            <Grid item xs={12}>
                                                <Camera
                                                    onTakePhoto={(dataUri) =>
                                                        handleTakePhoto(dataUri)
                                                    }
                                                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                                                    isImageMirror={false}
                                                />
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Button
                                                        onClick={() => reloadCamera()}
                                                        variant="contained"
                                                        className={classes.formButton}
                                                        style={{ margin: 5, width: 'fit-content' }}
                                                    >
                                                        {t('Audit.layout.btn-reload-camera')}
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button
                                                        onClick={() => setUploadingFiles(true)}
                                                        variant="contained"
                                                        className={classes.formButton}
                                                    >
                                                        {t('Audit.layout.btn-upload-file')}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                    {uploadingFiles && (
                                        <Grid item xs={12}>
                                            <div
                                                style={{
                                                    backgroundColor: 'white',
                                                    padding: 10,
                                                    marginBottom: 10,
                                                }}
                                            >
                                                <input
                                                    type="file"
                                                    title={t('Audit.layout.input-upload-file')}
                                                    onChange={handleFileUpload}
                                                    multiple
                                                />
                                            </div>
                                            <Button
                                                onClick={() => setUploadingFiles(false)}
                                                variant="contained"
                                                className={classes.formButton}
                                                style={{ margin: 5, width: 'fit-content' }}
                                            >
                                                {t('Audit.layout.btn-close-file-upload')}
                                            </Button>
                                        </Grid>
                                    )}
                                    <div
                                        style={{
                                            width: '90vw',
                                            margin: '0 auto',
                                            whiteSpace: 'nowrap',
                                            overflowX: 'auto',
                                        }}
                                    >
                                        <div style={{ width: '100%' }}>
                                            <div>
                                                {imageCapture.map((image: any, index: number) => (
                                                    <div className={classes.image} key={index}>
                                                        <ClearIcon
                                                            className={classes.btnDelete}
                                                            onClick={() => {
                                                                setImageCapture([
                                                                    ...imageCapture.slice(0, index),
                                                                    ...imageCapture.slice(
                                                                        index + 1,
                                                                        imageCapture.length
                                                                    ),
                                                                ]);
                                                            }}
                                                        />
                                                        <img
                                                            className={classes.imgPreview}
                                                            src={image}
                                                        />
                                                    </div>
                                                ))}
                                                {selectedFiles && (
                                                    <div
                                                        className={classes.filesContainer}
                                                        style={{ marginBottom: 15 }}
                                                    >
                                                        {selectedFiles.map(
                                                            (file: File, index: number) => {
                                                                return (
                                                                    <div
                                                                        className={classes.file}
                                                                        key={index}
                                                                    >
                                                                        <div
                                                                            className={
                                                                                classes.fileDelete
                                                                            }
                                                                        >
                                                                            <ClearIcon
                                                                                className={
                                                                                    classes.btnDeleteFile
                                                                                }
                                                                                onClick={() => {
                                                                                    setSelectedFiles(
                                                                                        [
                                                                                            ...selectedFiles.slice(
                                                                                                0,
                                                                                                index
                                                                                            ),
                                                                                            ...selectedFiles.slice(
                                                                                                index +
                                                                                                    1,
                                                                                                selectedFiles.length
                                                                                            ),
                                                                                        ]
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div>{file.name}</div>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                {takingPicture && !uploadingFiles && (
                                    <Grid item xs={12}>
                                        <Field
                                            name={cFieldNames.comment}
                                            label={t('Audit.layout.input-comment-required')}
                                            placeholder={t('Audit.layout.input-comment-required')}
                                            rows={3}
                                            onChange={(c: any) => {
                                                setTmpLastComment(c.target.value);
                                                c.target.value.length > 0
                                                    ? setCommentRequiredError(false)
                                                    : setCommentRequiredError(true);
                                            }}
                                            component={'textarea'}
                                            style={{ width: '100%' }}
                                        />
                                    </Grid>
                                )}
                                {photoRequiredError && (
                                    <Grid item xs={12} style={{ padding: '0 10px' }}>
                                        <Alert severity="error">
                                            {t('Audit.layout.L4.validation.photo-required')}
                                        </Alert>
                                    </Grid>
                                )}
                                {commentRequiredError && (
                                    <Grid item xs={12} style={{ padding: '0 10px' }}>
                                        <Alert severity="error">
                                            {t('Audit.validation.comment-required')}
                                        </Alert>
                                    </Grid>
                                )}
                                {takingPicture &&
                                    !commentRequiredError &&
                                    !photoRequiredError &&
                                    !dangerousFindings &&
                                    !uploadingFiles && (
                                        <>
                                            <Button
                                                variant="outline"
                                                className={classes.formButton}
                                                style={{ margin: '0 auto' }}
                                                onClick={() => setIsFinishing(false)}
                                                type="submit"
                                            >
                                                {t('Audit.layout.L4.next-finding')}
                                            </Button>
                                        </>
                                    )}
                                {takingPicture &&
                                    !commentRequiredError &&
                                    !photoRequiredError &&
                                    dangerousFindings &&
                                    !uploadingFiles && (
                                        <Box sx={{ display: 'flex', width: '100%' }}>
                                            <Button
                                                variant="outline"
                                                className={classes.formButton}
                                                style={{ margin: '0 auto' }}
                                                onClick={() => {
                                                    setIsDangerous(false);
                                                    setIsFinishing(false);
                                                }}
                                                type="submit"
                                            >
                                                {t(
                                                    'Audit.layout.L4.next-finding-not-dangerous-findings'
                                                )}
                                            </Button>
                                            <Button
                                                variant="outline"
                                                className={`${classes.formButton} dangerous`}
                                                style={{ margin: '0 auto' }}
                                                onClick={() => {
                                                    setIsDangerous(true);
                                                    setIsFinishing(false);
                                                }}
                                                type="submit"
                                            >
                                                {t(
                                                    'Audit.layout.L4.next-finding-dangerous-findings'
                                                )}
                                            </Button>
                                        </Box>
                                    )}
                            </Grid>
                        </Content>
                        <Footer>
                            <Box sx={{ display: 'flex' }}>
                                {!uploadingFiles && (
                                    <Button
                                        onClick={() => setIsStorno(true)}
                                        variant="contained"
                                        className={classes.navButton}
                                        type="submit"
                                    >
                                        {t('Storno')}
                                    </Button>
                                )}
                                {takingPicture && !uploadingFiles && (
                                    <Button
                                        variant="outline"
                                        className={classes.navButton}
                                        onClick={() => setIsFinishing(true)}
                                        disabled={
                                            (answers.length === 0 &&
                                                (imageCapture.length === 0 ||
                                                    tmpLastComment === '')) ||
                                            (dangerousFindings &&
                                                (imageCapture.length !== 0 ||
                                                    tmpLastComment !== ''))
                                        }
                                        type="submit"
                                    >
                                        {answers.length < 1 &&
                                        (imageCapture.length < 1 || tmpLastComment.length < 1)
                                            ? t('Save-data')
                                            : imageCapture.length > 0 && tmpLastComment.length > 0
                                            ? t('Save-data') + ' (' + (answers.length + 1) + ')'
                                            : t('Save-data') + ' (' + answers.length + ')'}
                                    </Button>
                                )}
                            </Box>
                        </Footer>
                        <AlertDialog
                            open={openAlertStorno}
                            title={t('Audit.layout.modal.unsaved-data')}
                            content={t('Audit.layout.modal.unsaved-data-confirm')}
                            handleClose={() => {
                                setIsStorno(false);
                                setOpenAlertStorno(false);
                            }}
                            btn1={{ title: t('Audit.layout.modal.option-no') }}
                            btn2={{ title: t('Audit.layout.modal.option-yes') }}
                            handleConfirm={() => history.push(appPaths.AUDIT)}
                        />
                        <AlertDialog
                            open={openAlertFinish}
                            title={t('Audit.layout.modal.unsaved-data')}
                            content={errorMessage}
                            handleClose={() => {
                                setOpenAlertFinish(false);
                            }}
                            handleConfirm={() => finishChecklist(false)}
                            btn1={{
                                title: t('Back'),
                                color: 'primary',
                                variant: 'contained',
                            }}
                            btn2={{
                                title: t('Audit.layout.L4.modal.save-data'),
                                color: 'primary',
                                variant: 'contained',
                            }}
                        />
                    </Form>
                )}
            </Formik>
        </>
    );
};
