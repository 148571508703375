import { makeStyles } from '@material-ui/core';

const orange = '#ea8503';
const white = '#fff';
const black = '#000';
const red = '#ff0000';

export const useStyles = makeStyles(() => ({
    heading: {
        fontSize: '3rem',
        color: white,
        textAlign: 'center',
    },
    subHeading: {
        fontSize: '1.5rem',
        color: white,
    },
    labelText: {
        fontSize: '1.5rem',
        color: orange,
        textAlign: 'center',
    },
    questionType: {
        fontSize: '1.5rem',
        color: white,
        textAlign: 'center',
        marginBottom: 10,
    },
    warningText: {
        fontSize: '1.5rem',
        color: red,
        textAlign: 'center',
    },
    questionText: {
        fontSize: '1.2rem',
        color: white,
    },
    navButton: {
        fontSize: '1.2rem',
        width: '100%',
        height: '50px',
        borderRadius: 0,
        backgroundColor: orange,
        border: '1px solid #fff',
        textTransform: 'uppercase',
        color: white,
        '&:hover': {
            backgroundColor: orange,
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: orange,
        },
        '&:visited': {
            boxShadow: 'none',
            backgroundColor: orange,
        },
        '&.dangerous': {
            backgroundColor: red,
        },
    },
    formLabel: {
        color: orange,
    },
    formButton: {
        fontSize: '1.2rem',
        width: '80%',
        minHeight: '50px',
        backgroundColor: orange,
        border: '1px solid #fff',
        borderRadius: 0,
        textTransform: 'uppercase',
        '&:hover': {
            backgroundColor: orange,
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: orange,
        },
        '&:visited': {
            boxShadow: 'none',
            backgroundColor: orange,
        },
        '&.dangerous': {
            backgroundColor: red,
        },
    },
    infoButton: {
        fontSize: '1.5rem',
        width: '80%',
        height: '50px',
        margin: '5px 0 10px 0',
        backgroundColor: white,
        color: black,
        borderRadius: 0,
        textTransform: 'uppercase',
        '&:hover': {
            backgroundColor: white,
            color: black,
        },
        '&:active': {
            color: black,
            backgroundColor: white,
        },
        '&:visited': {
            color: black,
            backgroundColor: white,
        },
    },

    // Layout 3 ////////////////////////
    answerContainer: {
        color: black,
        backgroundColor: white,
        margin: '5px 0px',
        padding: '5px',
    },
    answerContainerActive: {
        color: white,
        backgroundColor: orange,
        margin: '5px 0px',
        padding: '5px',
    },
    answerLabel: {
        margin: '0 15px 0 0',
        display: 'inline-block',
    },
    questionIcon: {
        color: orange,
    },
    questionIconActive: {
        color: white,
    },

    /////////////////////////////////

    image: {
        width: '130px',
        maxHeight: '130px',
        position: 'relative',
        display: 'inline',
        margin: '2px',
    },

    filesContainer: {
        display: 'flex',
        gap: '10px',
        marginTop: '10px',
    },
    file: {
        backgroundColor: 'white',
        color: 'black',
        fontSize: 23,
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    fileDelete: {
        height: 30,
        display: 'flex',
        justifyContent: 'end',
    },
    btnDeleteFile: {
        color: red,
        position: 'relative',
        cursor: 'pointer',
    },

    imgPreview: {
        maxHeight: '130px',
        margin: '2px',
        border: '1px solid #ddd',
        borderRadius: '4px',
        padding: '5px',
        width: '130px',
    },
    btnDelete: {
        color: red,
        position: 'absolute',
        cursor: 'pointer',
        right: '10px',
        top: '-35px',
    },
    formSelect: {
        width: '80%',
        backgroundColor: '#fff',
        height: '50px',
        fontSize: '1.2rem',
        textAlign: 'center',
    },
    formAuditText: {
        width: '100%',
        backgroundColor: 'red',
        height: '50px',
        fontSize: '1.2rem',
        textAlign: 'center',
        border: 'none',
    },
}));
