import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router';
import { Zone } from '../../../types/ZoneApiResponse';
import { Answer } from '../../../types/ChecklistAnswerApiResponse';
import { useResultSave } from '../Common/ResultSave';
import CircularStatic from '../../../components/CircularProgressWithLabel/CircularProgressWithLabel';
import { useOnlineCheck } from '../../../hooks/useOnlineCheck';
import { QuestionSummaryL4 } from './QuestionSummaryL4';
import '../Common/audit.scss';
import { GembaType } from './AuditLayout_4';
import { QuestionFormL4 } from '../../../form/questionsSurveyL4/QuestionsSurveyL4Form';
import { Content } from '../Common/Layout/Content';
import { appPaths } from '../../../paths';
import { useTranslation } from '../../../contexts/translationContext';
import { getItemApi } from '../../../apiUtils';
import { API_URL } from '../../../apiTypes';
import { useLoading } from '../../../contexts/loadingContext';

export interface AnswerAttachment {
    questionId: number;
    photo: any;
}

interface Props {
    gembaType?: GembaType;
    zoneList?: Zone[];
    auditsForUser: any;
    dangerousFindings: boolean;
}

/**
 * Otázky pro layout 4
 * @param param0
 * @returns
 */
export const QuestionsLayout_4 = ({
    gembaType,
    zoneList,
    auditsForUser,
    dangerousFindings,
}: Props) => {
    const { isOnline, setOnline } = useOnlineCheck();
    const { t } = useTranslation();
    const { startLoading, stopLoading } = useLoading();
    const history = useHistory();

    // pole odpovědí
    const [answers, setAnswers] = useState<Answer[]>([]);
    // pole attachmentů s id odpovědi
    const [answersAttachments, setAnswersAttachments] = useState<AnswerAttachment[]>([]);
    // jestli jsou všechny otázky zodpovězeny, zobraz Dokončit form
    const [checklistFinished, setCheckListFinished] = useState<boolean>();
    // indikátor zapnutí / vypnutí foťáku
    const [takingPicture, setTakingPicture] = useState<boolean>(true);
    // pole souborů - ne fotek
    const [answersAttachmentsFiles, setAnswersAttachmentsFiles] = useState<any[]>([]);
    // množství aktuálně nahraných fotek - pro progres indikátor
    const [actualValue, setActualValue] = useState<number>(0);
    // pro párování fotky a komentářů
    const [counter, setCounter] = useState<number>(0);
    // pro každý audit vytvářím nový checklist
    const { handleResultSave, attUploaded, isLoading } = useResultSave();

    const handleCreateAttachments = (photos: any, auditId: number, files: any) => {
        // každou vyfocenou fotku uložit do attachments s id zóny u které se fotilo
        let _answerAttachments: AnswerAttachment[] = [...answersAttachments];
        photos.map((photo: any) => {
            let tmpAnswerAttachment = {
                // questionId jen pro párování komentářů a fotek
                questionId: counter,
                auditId: auditId,
                photo: photo,
            } as AnswerAttachment;
            _answerAttachments = [..._answerAttachments, tmpAnswerAttachment];
        });
        setAnswersAttachments(_answerAttachments);

        if (!files) return;
        let _answerAttachmentsFiles = [...answersAttachmentsFiles];
        files.map((file: any) => {
            let _file = file;
            _file.questionId = counter;
            (_file.auditId = auditId),
                (_answerAttachmentsFiles = [..._answerAttachmentsFiles, file]);
        });
        setAnswersAttachmentsFiles(_answerAttachmentsFiles);
    };

    // Uloží odpovědi, je nutné být online a připojen na VPN
    const handleSubmitData = async () => {
        if (window.navigator.onLine) {
            startLoading();
            getItemApi({
                url: `${API_URL.IS_AUTHENTICATED}`,
                params: {},
                callAfterSuccess: () => {
                    let tmpAuditList: any[] = [];
                    answers.map((answer: any) => {
                        let index = tmpAuditList.findIndex(
                            (audit: any) => audit.auditId === answer.auditId
                        );
                        if (index === -1) {
                            tmpAuditList = [
                                ...tmpAuditList,
                                auditsForUser.find((a: any) => a.auditId === answer.auditId),
                            ];
                        }
                    });
                    tmpAuditList.map((audit: any) => {
                        handleResultSave(
                            {
                                auditId: audit.auditId,
                                auditLevel: audit.auditLevel,
                                auditorNetId: auditsForUser[0].auditorNetId,
                            },
                            answers.filter((answer: any) => answer.auditId === audit.auditId),
                            answersAttachments.filter(
                                (attachment: any) => attachment.auditId === audit.auditId
                            ),
                            undefined,
                            answersAttachmentsFiles.filter(
                                (attachment: any) => attachment.auditId === audit.auditId
                            )
                        );
                    });
                },
                callAfterError: () => {
                    stopLoading();
                    setOnline(false);
                },
            });
        } else {
            stopLoading();
            setOnline(false);
        }
    };

    // Uloží otázku
    const onSubmitAnswer = (auditId: number, isDangerous: boolean | null, comment?: string) => {
        let tmpAnswer: Answer = {
            ordering: '0',
            questionText: 'None',
            typeId: null,
            correctAnswer: 'None',
            answer: 'No',
            answerCustomText: null,
            answerScore: null,
            comment: '',
            isDangerous: isDangerous,
            questionTypeId: '',
            questionTypeName: '',
            // pro zjištění jaké auditId poslat
            auditId: auditId,
            // jen pro párování attachmentů a komentářů
            id: counter,
        };
        if (comment) tmpAnswer.comment = comment;
        setAnswers([...answers, tmpAnswer]);
    };

    const onFinishUpload = () => {
        setAnswers([]);
        setAnswersAttachments([]);
        setCheckListFinished(false);
        setActualValue(0);
        history.push(appPaths.AUDIT);
    };

    // pro progres nahrávání fotek - zavolá se po uploadu každé fotky
    useEffect(() => {
        if (!attUploaded) return;
        let numOfUploadedAtt = actualValue + 1;
        setActualValue(numOfUploadedAtt);
    }, [attUploaded]);

    return (
        <Box>
            {/* focení & komentování */}
            {gembaType && zoneList && !checklistFinished && (
                <QuestionFormL4
                    counter={counter}
                    setCounter={setCounter}
                    gembaType={gembaType}
                    zoneList={zoneList}
                    onSubmit={onSubmitAnswer}
                    setTakingPicture={setTakingPicture}
                    takingPicture={takingPicture}
                    handleCreateAttachments={handleCreateAttachments}
                    setCheckListFinished={setCheckListFinished}
                    answers={answers}
                    dangerousFindings={dangerousFindings}
                />
            )}
            {/* Finální obrazovku - souhrn */}
            {checklistFinished && (
                <QuestionSummaryL4
                    showOfflineIndicator={!isOnline}
                    onFinish={handleSubmitData}
                    answers={answers}
                />
            )}
            {/* Indikátor, že dochází k ukládání */}
            {isLoading && (
                <Content bottomOffset={30} textAlign={'center'}>
                    <CircularStatic
                        totalValue={answersAttachments.length + 1}
                        actualValue={actualValue + 1}
                        onFinish={() => onFinishUpload()}
                    />
                </Content>
            )}
        </Box>
    );
};
