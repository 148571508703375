import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useHistory } from 'react-router';
import { API_URL } from '../../../apiTypes';
import { fetcher, getItemApi } from '../../../apiUtils';
import useSWR from 'swr';
import { Zone } from '../../../types/ZoneApiResponse';
import { QuestionForm } from '../../../form/questionsSurvey/QuestionsSurveyForm';
import { AuditQuestion } from '../../../types/AuditQuestionApiResponse';
import { Answer, AnswerValue } from '../../../types/ChecklistAnswerApiResponse';
import { useResultSave } from '../Common/ResultSave';
import CircularStatic from '../../../components/CircularProgressWithLabel/CircularProgressWithLabel';
import { appPaths } from '../../../paths';
import { useOnlineCheck } from '../../../hooks/useOnlineCheck';
import { QuestionHeadline } from '../Common/Questions/QuestionHeadline';
import { QuestionCounter } from '../Common/Questions/QuestionCounter';
import { QuestionSummary } from '../Common/Questions/QuestionSummary';
import '../Common/audit.scss';
import { Header } from '../Common/Layout/Header';
import { Content } from '../Common/Layout/Content';
import { useTranslation } from '../../../contexts/translationContext';
import { AlertDialog } from '../../../components/AlertDialog';
import { useLoading } from '../../../contexts/loadingContext';
import { AuditTypeObservedPersonForm } from '../../../types/AuditTypeObservedPersonForm';
import { QuestionType } from '../Common/Questions/QuestionType';

export interface AnswerAttachment {
    questionId: number;
    photo: any;
    isSummary?: boolean;
}

interface Props {
    zone: Zone;
    selectedAudit: any;
    observedPersonRequired?: AuditTypeObservedPersonForm;
}

/**
 * Otázky pro layout 1
 * @param param0
 * @returns
 */
export const QuestionsLayout_1 = ({ zone, selectedAudit, observedPersonRequired }: Props) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { isOnline, setOnline } = useOnlineCheck();
    const { startLoading, stopLoading } = useLoading();

    // vybraná otázka
    const [selectedQuestion, setSelectedQuestion] = useState<AuditQuestion>();
    // Filtrované otázky dle levelu
    const [questions, setQuestions] = useState<AuditQuestion[]>();
    // index v poli questions
    const [counter, setCounter] = useState<number>(0);
    // při nesprávné odpovědi (correctAnswer !== answer) - objeví se form pro focení atd.
    const [isWrongAnswer, setIsWrongAnswer] = useState<boolean>(false);
    // pole odpovědí
    const [answers, setAnswers] = useState<Answer[]>([]);
    // pole attachmentů s id odpovědi
    const [answersAttachments, setAnswersAttachments] = useState<AnswerAttachment[]>([]);
    // pole souborů - ne fotek
    const [answersAttachmentsFiles, setAnswersAttachmentsFiles] = useState<any[]>([]);
    // jestli jsou všechny otázky zodpovězeny, zobraz Dokončit form
    const [checklistFinished, setCheckListFinished] = useState<boolean>();
    // kliklo se na Vytvořit fotku -> skrýt vše mimo foťáku a preview předchozích fotek
    const [takingPicture, setTakingPicture] = useState<boolean>(false);
    // modal při kliku na Storno
    const [openAlert, setOpenAlert] = useState<boolean>(false);

    const { handleResultSave, actualValue, isLoading } = useResultSave();

    // Načte otázky podle auditu
    const { data: allQuestions, isValidating: loadingQuestions } = useSWR<AuditQuestion[]>(
        `${API_URL.AUDIT_QUESTION_LIST}?parentId=${selectedAudit.auditId}`,
        fetcher,
        {
            onSuccess: (res) =>
                setQuestions(
                    res.filter(
                        (question: AuditQuestion) => question.level === selectedAudit?.auditLevel
                    )
                ),
        }
    );

    const handleCreateAttachments = (photos: any, files: any) => {
        // každou vyfocenou fotku uložit do attachments s id otázky u které se fotilo
        let _answerAttachments: AnswerAttachment[] = [...answersAttachments];
        photos.map((photo: any) => {
            let tmpAnswerAttachment = {
                questionId: selectedQuestion?.id,
                photo: photo,
            } as AnswerAttachment;
            _answerAttachments = [..._answerAttachments, tmpAnswerAttachment];
        });
        setAnswersAttachments(_answerAttachments);

        if (!files) return;
        let _answerAttachmentsFiles = [...answersAttachmentsFiles];
        files.map((file: any) => {
            let _file = file;
            _file.questionId = selectedQuestion?.id;
            _answerAttachmentsFiles = [..._answerAttachmentsFiles, file];
        });
        setAnswersAttachmentsFiles(_answerAttachmentsFiles);
    };

    // Uloží odpovědi, je nutné být online a připojen na VPN
    const handleSubmitData = async () => {
        if (window.navigator.onLine) {
            startLoading();
            getItemApi({
                url: `${API_URL.IS_AUTHENTICATED}`,
                params: {},
                callAfterSuccess: () => {
                    handleResultSave(
                        selectedAudit,
                        answers,
                        answersAttachments,
                        observedPersonRequired,
                        answersAttachmentsFiles
                    );
                },
                callAfterError: () => {
                    stopLoading();
                    setOnline(false);
                },
            });
        } else {
            setOnline(false);
        }
    };

    // Uloží otázku
    const onSubmitAnswer = (answer: AnswerValue, isDangerous: boolean, comment?: string) => {
        if (!selectedQuestion || !questions) return;
        // Pokud už nejsou otázky, zobrazíme summary
        if (counter === questions.length - 1) setCheckListFinished(true);

        let tmpAnswer: Answer = {
            ordering: selectedQuestion.ordering.toString(),
            questionText: selectedQuestion.questionText,
            typeId: selectedQuestion.typeId,
            correctAnswer: selectedQuestion.correctAnswer,
            answerCustomText: null,
            answerScore: null,
            answer: answer,
            comment: '',
            questionTypeId: '',
            questionTypeName: '',
            id: selectedQuestion.id,
            isDangerous: selectedAudit.auditType.dangerousFindings ? isDangerous : null,
        };

        // jesli správná odpověď ulož do Answers když ne, tak zobraz form na fotky a komentář
        if (
            (selectedQuestion.correctAnswer === AnswerValue.YES && answer === AnswerValue.YES) ||
            (selectedQuestion.correctAnswer === AnswerValue.NO && answer === AnswerValue.NO)
        ) {
            setAnswers([...answers, tmpAnswer]);
            setCounter(counter + 1);
        } else {
            if (comment) tmpAnswer.comment = comment;
            setAnswers([...answers, tmpAnswer]);
            setCounter(counter + 1);
            setIsWrongAnswer(false);
        }
    };

    // Když dojde k posunu na další otázku, nebo při initu, vybereme otázku ze zásobníku
    useEffect(() => {
        if (!questions) return;
        if (counter <= questions.length) setSelectedQuestion(questions[counter]);
    }, [counter, questions]);

    return (
        <>
            <Box>
                <Header paddingTop={60} paddingBottom={20}>
                    {/* Nadpis otázky, pokud je odpovězeno záporně, nezobrazujeme */}
                    {!isWrongAnswer && (
                        <QuestionHeadline
                            title={selectedAudit.auditType.name}
                            subTitle={zone.name}
                            textAlign={'center'}
                        />
                    )}

                    {/* Počítadlo otázek, nezobrazujeme, když se fotí a na konci */}
                    {!checklistFinished && !takingPicture && !!questions && (
                        <Grid item xs={12}>
                            <QuestionCounter current={counter + 1} total={questions.length} />
                        </Grid>
                    )}
                </Header>

                {questions && selectedQuestion && selectedAudit && zone && !checklistFinished && (
                    <QuestionForm
                        onSubmit={onSubmitAnswer}
                        question={selectedQuestion}
                        isWrongAnswer={isWrongAnswer}
                        setIsWrongAnswer={setIsWrongAnswer}
                        setTakingPicture={setTakingPicture}
                        takingPicture={takingPicture}
                        handleCreateAttachments={handleCreateAttachments}
                        dangerousFindings={selectedAudit.auditType.dangerousFindings}
                    />
                )}

                {/* Finální obrazovku - souhrn */}
                {checklistFinished && (
                    <>
                        <QuestionSummary
                            showOfflineIndicator={!isOnline}
                            onFinish={handleSubmitData}
                            setOpenAlert={setOpenAlert}
                        />
                        {/* Indikátor, že dochází k ukládání */}
                        {questions && isLoading && (
                            <Content bottomOffset={30} textAlign={'center'}>
                                <CircularStatic
                                    totalValue={questions?.length}
                                    actualValue={actualValue + 1}
                                    onFinish={() => history.push(appPaths.AUDIT)}
                                />
                            </Content>
                        )}
                    </>
                )}
            </Box>
            <AlertDialog
                open={openAlert}
                title={t('Audit.layout.modal.unsaved-data')}
                content={t('Audit.layout.modal.unsaved-data-confirm')}
                handleClose={() => {
                    setOpenAlert(false);
                }}
                btn1={{ title: t('Audit.layout.modal.option-no') }}
                btn2={{ title: t('Audit.layout.modal.option-yes') }}
                handleConfirm={() => history.push(appPaths.AUDIT)}
            />
        </>
    );
};
