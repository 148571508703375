import { useState } from 'react';
import { API_URL } from '../../../apiTypes';
import { postApi, postApiFormData } from '../../../apiUtils';
import { useLoading } from '../../../contexts/loadingContext';
import { AuditLayoutId } from '../../../types/AuditMobile';
import { Answer } from '../../../types/ChecklistAnswerApiResponse';
import { AnswerAttachment } from '../Layout_1/QuestionsLayout_1';
import { AuditTypeObservedPersonForm } from '../../../types/AuditTypeObservedPersonForm';

export function useResultSave() {
    const [actualValue, setActualValue] = useState<number>(0);
    // loading s počítáním progresu
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // loading pro disabled tlačítka
    const { startLoading, stopLoading } = useLoading();
    const [attUploaded, setAttUploaded] = useState<any>();

    const handleResultSave = async (
        selectedAudit: any,
        answers: Answer[],
        answersAttachments: AnswerAttachment[],
        observedPersonRequired?: AuditTypeObservedPersonForm,
        answersAttachmentsFiles?: any[]
    ) => {
        setIsLoading(true);
        startLoading();
        let checkListId = null;
        await postApi({
            url: API_URL.CHECK_LIST_CREATE,
            data: {
                auditId: selectedAudit?.auditId,
                auditorNetId: selectedAudit?.auditorNetId,
                auditLevel: selectedAudit?.auditLevel,
                observedPersonNetId: observedPersonRequired?.observedPersonNet?.netId,
                observedPersonExternal: observedPersonRequired?.observedPersonExternal,
                observedActivity: observedPersonRequired?.observedActivity,
            },
            callAfterSuccess: async (res) => {
                // pro všechny answers -> checkListAnswer/createItem
                checkListId = res.data.id;
                await Promise.all(
                    answers.map(async (answer: Answer, index: number) => {
                        await setActualValue(index);
                        const postResult: any = await postApi({
                            url: API_URL.CHECK_LIST_ANSWER_CREATE,
                            data: {
                                checklistId: checkListId,
                                ordering: answer.ordering,
                                questionText: answer.questionText,
                                typeId: answer.questionTypeId,
                                correctAnswer: answer.correctAnswer,
                                answerCustomText: answer.answerCustomText,
                                answerScore: answer.answerScore,
                                answer: answer.answer,
                                comment: answer.comment,
                                isDangerous: answer.isDangerous,
                            },
                        });
                        if (postResult) {
                            // attachments
                            if (answer.answer === answer.correctAnswer && answer.answer !== 'None')
                                return;
                            const tmpAnswersAttachments =
                                answer.questionText === '__WEEKLY_TOPIC__'
                                    ? answersAttachments.filter(
                                          (attachment: AnswerAttachment) =>
                                              attachment.questionId === undefined &&
                                              attachment.isSummary
                                      )
                                    : answersAttachments.filter(
                                          (attachment: AnswerAttachment) =>
                                              attachment.questionId === answer.id
                                      );

                            await Promise.all(
                                tmpAnswersAttachments.map(async (attachment: AnswerAttachment) => {
                                    if (
                                        attachment.questionId === answer.id ||
                                        attachment.isSummary
                                    ) {
                                        const formData = new FormData();
                                        let blob = await fetch(attachment.photo).then((res) =>
                                            res.blob()
                                        );
                                        const myFile = new File([blob], 'image.png', {
                                            type: blob.type,
                                        });
                                        formData.append('file', myFile);

                                        await postApiFormData({
                                            url: `${API_URL.CHECK_LIST_ANSWER_ATTACHMENT_UPLOAD}?answerId=${postResult.id}`,
                                            data: formData,
                                        }).then((res) => {
                                            setAttUploaded(res);
                                        });
                                    }
                                })
                            );

                            if (answersAttachmentsFiles && answersAttachmentsFiles.length > 0)
                                await Promise.all(
                                    answersAttachmentsFiles.map(async (file: any) => {
                                        if (file.questionId === answer.id) {
                                            const formData = new FormData();
                                            formData.append('file', file);
                                            await postApiFormData({
                                                url: `${API_URL.CHECK_LIST_ANSWER_ATTACHMENT_UPLOAD}?answerId=${postResult.id}`,
                                                data: formData,
                                            }).then((res) => {
                                                setAttUploaded(res);
                                            });
                                        }
                                    })
                                );
                        }
                    })
                );

                await postApi({
                    url: `${API_URL.CHECK_LIST_IS_FINISHED}?id=${checkListId}`,
                }).then(() => {
                    if (selectedAudit.auditId !== AuditLayoutId.Layout4) stopLoading();
                });
            },
        });
    };

    return {
        handleResultSave,
        actualValue,
        isLoading,
        attUploaded,
    };
}
