import { Button, Grid, Modal } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { PageWrapper } from '../../containers/PageWrapper';
import { API_URL } from '../../apiTypes';
import { fetcher, postApi, postApiFormData, putApi } from '../../apiUtils';
import { RouteParams } from '../Permissions/Permission';
import StickyHeadTable from '../../components/Table/Table';
import { ColumnsAnswer, ColumnsAttachment } from './Columns';
import { TaskDetailInfo } from '../../containers/TaskDetailForm';
import { TaskAttachmentApiResponse } from '../../types/TaskAttachmentApiResponse';
import { Task } from '../Tasks/types';
import { Answer } from '../../types/ChecklistAnswerApiResponse';
import { TaskAction } from '../../types/UserTaskActionApiResponse';
import { AnswerAttachment } from '../../types/ChecklistAnswerAttachmentApiResponse';
import { TaskDetailModalForm } from '../../form/task/TaskActionModalForm';
import { TaskCommentModalForm } from '../../form/task/TaskCommentModalForm';
import { useStyles } from './TasksDetail.styles';
import { useAuthContext } from '../../contexts/AuthContext';
import { useTranslation } from '../../contexts/translationContext';
import { UserRoles } from '../../types/applicationUser.types';
import { NotificationManager } from 'react-notifications';
import { RestaurantMenu } from '@material-ui/icons';
import { AuditLayoutId } from '../../types/AuditMobile';

export const TasksDetail = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { id } = useParams<RouteParams>();
    const numericId = Number(id);
    const [selectedFile, setSelectedFile] = useState<FileList | null>(null);
    const { appUser } = useAuthContext();
    const [action, setAction] = useState('');

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const [openComment, setOpenComment] = useState(false);
    const handleCloseComment = () => setOpenComment(false);

    const { data, error, mutate } = useSWR<TaskAttachmentApiResponse>(
        `${API_URL.USER_TASK_ATTACHMENT_GET_LIST}?parentId=${numericId}`,
        fetcher
    );

    const {
        data: actionsAllowedData,
        error: actionsAllowedError,
        mutate: actionsAllowedMutate,
    } = useSWR<string[]>(
        appUser ? `${API_URL.USER_TASK_GET_ALLOWED_ACTIONS}?id=${numericId}` : null,
        fetcher
    );
    const {
        data: userTaskDetailData,
        error: userTaskError,
        mutate: userTaskMutate,
    } = useSWR<Task>(`${API_URL.USER_TASK_GET_ITEM}?id=${numericId}`, fetcher);

    const {
        data: actionHistoryData,
        error: actionHistoryError,
        mutate: actionHistoryMutate,
    } = useSWR<TaskAction[]>(`${API_URL.USER_TASK_ACTION_GET_LIST}?parentId=${numericId}`, fetcher);

    const {
        data: answerData,
        error: answerError,
        mutate: answerMutate,
    } = useSWR<Answer>(
        userTaskDetailData?.parentAnswerId
            ? `${API_URL.CHECK_LIST_ANSWER_GET_ITEM}?id=${userTaskDetailData?.parentAnswerId}`
            : null,
        fetcher
    );

    const {
        data: answerAttachmentData,
        error: answerAttachmentError,
        mutate: answerAttachmentMutate,
    } = useSWR<AnswerAttachment[]>(
        userTaskDetailData?.parentAnswerId
            ? `${API_URL.CHECK_LIST_ANSWER_ATTACHMENT_GET_LIST}?answerId=${userTaskDetailData?.parentAnswerId}`
            : null,
        fetcher
    );

    const handleActiveAction = (action: string) => {
        setAction(action);
        setOpen(true);
    };

    const handleOnWriteComment = () => {
        setOpenComment(true);
    };

    const handleOnSubmitComment = (values) => {
        postApi({
            url: API_URL.USER_TASK_ADD_COMMENT + `?id=${numericId}&comment=${values.comment}`,
            data: values,
            callAfterSuccess: () => {
                setOpenComment(false);
                actionHistoryMutate();
                NotificationManager.success(t('Notify.item-created'));
            },
        });
    };

    const handleOnSubmitAction = async (values) => {
        switch (action) {
            case 'ChangeZone':
                putApi({
                    url: API_URL.USER_TASK_CHANGE_ZONE + `?id=${numericId}&zoneId=${values.zoneId}`,
                    callAfterSuccess: () => {
                        setOpen(false);
                        mutateAll();
                        NotificationManager.success(t('Notify.request-successful'));
                    },
                });
                break;
            case 'Assign':
                putApi({
                    url:
                        API_URL.USER_TASK_ASSIGN +
                        `?id=${numericId}&netId=${values.netId}&comment=${values.comment}`,
                    data: values,
                    callAfterSuccess: () => {
                        setOpen(false);
                        mutateAll();
                        NotificationManager.success(t('Notify.request-successful'));
                    },
                });
                break;
            case 'Finish':
                if (
                    userTaskDetailData &&
                    userTaskDetailData.auditTypeLayoutId !== AuditLayoutId.Layout6 &&
                    userTaskDetailData.auditTypeDangerousFindings &&
                    userTaskDetailData.safetyImpact === null
                ) {
                    NotificationManager.error(
                        t('Tasks.detail.notify.safety-impact-can-not-be-null')
                    );
                    return;
                }
                putApi({
                    url: API_URL.USER_TASK_FINISH + `?id=${numericId}&comment=${values.comment}`,
                    data: values,
                    callAfterSuccess: () => {
                        setOpen(false);
                        mutateAll();
                        NotificationManager.success(t('Notify.request-successful'));
                    },
                });
                break;
            case 'SetPlannedDate':
                putApi({
                    url:
                        API_URL.USER_TASK_SET_PLANNED_DATE +
                        `?id=${numericId}&date=${values.date}&comment=${values.comment}`,
                    data: values,
                    callAfterSuccess: () => {
                        setOpen(false);
                        mutateAll();
                        NotificationManager.success(t('Notify.request-successful'));
                    },
                });
                break;
            case 'Reopen':
                putApi({
                    url: API_URL.USER_TASK_REOPEN + `?id=${numericId}&comment=${values.comment}`,
                    data: values,
                    callAfterSuccess: () => {
                        setOpen(false);
                        mutateAll();
                        NotificationManager.success(t('Notify.request-successful'));
                    },
                });
                break;
            case 'Take':
                putApi({
                    url: API_URL.USER_TASK_TAKE + `?id=${numericId}&comment=${values.comment}`,
                    data: values,
                    callAfterSuccess: () => {
                        setOpen(false);
                        mutateAll();
                        NotificationManager.success(t('Notify.request-successful'));
                    },
                });
                break;
            case 'Deny':
                putApi({
                    url: API_URL.USER_TASK_DENY + `?id=${numericId}&comment=${values.comment}`,
                    data: values,
                    callAfterSuccess: () => {
                        setOpen(false);
                        mutateAll();
                        NotificationManager.success(t('Notify.request-successful'));
                    },
                    callAfterError(res) {
                        NotificationManager.error('Error');
                    },
                });
                break;
            default:
                break;
        }
    };

    const mutateAll = () => {
        userTaskMutate(), actionHistoryMutate(), actionsAllowedMutate(), answerMutate();
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (!event.target.files) return;
        setSelectedFile(event.target.files);
    };

    const handleOnSubmitAttachment = async () => {
        if (!selectedFile) return;
        const formData = new FormData();
        Array.from(selectedFile).forEach((file) => {
            formData.append('file', file);
        });

        postApiFormData<any>({
            url: API_URL.USER_TASK_ATTACHMENT_UPLOAD + `?parentId=${numericId}`,
            data: formData,
            callAfterSuccess: () => {
                mutate();
                actionHistoryMutate();
                NotificationManager.success(t('Notify.item-uploaded'));
            },
        });
    };

    const downloadFile = (fileUrl: string, fileName: string) => {
        // Create a new link
        const anchor = document.createElement('a');
        anchor.href = fileUrl;
        anchor.download = fileName;
        // Append to the DOM
        document.body.appendChild(anchor);
        // Trigger `click` event
        anchor.click();
        // Remove element from DOM
        document.body.removeChild(anchor);
    };

    const handleDownloadItem = (item: any) => {
        fetch(API_URL.USER_TASK_ATTACHMENT_GET + `?id=${item.id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => response.blob())
            .then((data) => {
                const fileUrl: string = URL.createObjectURL(data);
                downloadFile(fileUrl, item.name);
            });
    };

    const handleRedirectToEDocumentsReward = useCallback(() => {
        if (!userTaskDetailData) return;

        fetch(`${API_URL.USER_TASK_GET_CREATOR_NET_ID}?id=${userTaskDetailData.id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => response.text())
            .then((userId) => {
                fetch(API_URL.COMMON_GET_EDOCUMENTS_URL, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then((response) => response.text())
                    .then((url) => {
                        window.open(
                            `${url}${
                                url.slice(-1) !== '/' ? '/' : ''
                                //@ts-ignore
                            }extraordinary-reward-prorocol/new?redirectType=ELPA&username=${userId.replaceAll(
                                '"',
                                ''
                            )}&type=Improvement reward&justification=Odměna ze zlepšovacího návrhu: ${
                                userTaskDetailData.improvementProposalName
                            }&elpaTaskId=${userTaskDetailData.id}`,
                            '_blank'
                        );
                    });
            });
    }, [userTaskDetailData]);

    return (
        <>
            <PageWrapper
                loading={!userTaskDetailData || data === undefined}
                error={userTaskError || error}
            >
                <HeaderPage
                    heading={t('Tasks.detail.main-headline')}
                    stepName={t('Tasks.detail.main-headline') + ' ' + numericId}
                    breadCrumbSteps={[{ name: t('Tasks.main-headline'), pathToCutCount: 1 }]}
                    buttonSectionPermission={[UserRoles.Any]}
                    buttonSection={
                        <>
                            {actionsAllowedData && actionsAllowedData.indexOf('Assign') > -1 && (
                                <Button
                                    className={classes.actionButtons}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleActiveAction('Assign')}
                                >
                                    {t('Tasks.detail.action-button-1')}
                                </Button>
                            )}
                            {actionsAllowedData && actionsAllowedData.indexOf('Finish') > -1 && (
                                <Button
                                    className={classes.actionButtons}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleActiveAction('Finish')}
                                >
                                    {t('Tasks.detail.action-button-2')}
                                </Button>
                            )}
                            {actionsAllowedData &&
                                actionsAllowedData.indexOf('SetPlannedDate') > -1 && (
                                    <Button
                                        className={classes.actionButtons}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleActiveAction('SetPlannedDate')}
                                    >
                                        {t('Tasks.detail.action-button-3')}
                                    </Button>
                                )}
                            {actionsAllowedData && actionsAllowedData.indexOf('Reopen') > -1 && (
                                <Button
                                    className={classes.actionButtons}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleActiveAction('Reopen')}
                                >
                                    {t('Tasks.detail.action-button-4')}
                                </Button>
                            )}
                            {actionsAllowedData && actionsAllowedData.indexOf('Take') > -1 && (
                                <Button
                                    className={classes.actionButtons}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleActiveAction('Take')}
                                >
                                    {t('Tasks.detail.action-button-5')}
                                </Button>
                            )}
                            {actionsAllowedData &&
                                actionsAllowedData.indexOf('ChangeZone') > -1 && (
                                    <Button
                                        className={classes.actionButtons}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleActiveAction('ChangeZone')}
                                    >
                                        {t('Tasks.detail.action-button-6')}
                                    </Button>
                                )}
                            {actionsAllowedData &&
                                actionsAllowedData.indexOf('RedirectToEDocumentsReward') > -1 && (
                                    <Button
                                        className={classes.actionButtons}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleRedirectToEDocumentsReward()}
                                    >
                                        {t('Tasks.detail.action-button-7')}
                                    </Button>
                                )}
                            {actionsAllowedData && actionsAllowedData.indexOf('Deny') > -1 && (
                                <Button
                                    className={classes.actionButtons}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleActiveAction('Deny')}
                                >
                                    {t('Tasks.detail.action-button-8')}
                                </Button>
                            )}
                        </>
                    }
                />
                <Grid container spacing={3}>
                    <Grid item md={5}>
                        {userTaskDetailData && answerData && (
                            <TaskDetailInfo
                                data={userTaskDetailData}
                                answerData={answerData}
                                answerAttachmentData={answerAttachmentData}
                                onSafetyImpactChange={() => mutateAll()}
                            />
                        )}
                    </Grid>
                    <Grid item md={7}>
                        <Grid item md={12}>
                            <StickyHeadTable
                                rows={data}
                                columns={ColumnsAttachment(t)}
                                handleDownloadItem={handleDownloadItem}
                            />
                        </Grid>

                        {actionsAllowedData && actionsAllowedData.indexOf('UploadFile') > -1 && (
                            <Grid item md={12} className={classes.uploadGrid}>
                                <input
                                    id="contained-button-file"
                                    type="file"
                                    title={t('Tasks.detail.attachment-select')}
                                    onChange={handleFileUpload}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleOnSubmitAttachment}
                                >
                                    {t('Tasks.detail.attachment-upload')}
                                </Button>
                            </Grid>
                        )}
                        {actionHistoryData && (
                            <Grid item md={12} className={classes.historyTable}>
                                <StickyHeadTable
                                    rows={actionHistoryData}
                                    columns={ColumnsAnswer(t)}
                                />
                            </Grid>
                        )}
                        {actionsAllowedData && actionsAllowedData.indexOf('AddComment') > -1 && (
                            <Grid item md={12} className={classes.uploadGrid}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleOnWriteComment}
                                >
                                    {t('Tasks.detail.comment-add')}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </PageWrapper>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>
                    <TaskDetailModalForm
                        action={action}
                        onSubmit={handleOnSubmitAction}
                        onClose={handleClose}
                    />
                </div>
            </Modal>
            <Modal
                open={openComment}
                onClose={handleCloseComment}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>
                    <TaskCommentModalForm
                        onSubmit={handleOnSubmitComment}
                        onClose={handleCloseComment}
                    />
                </div>
            </Modal>
        </>
    );
};
