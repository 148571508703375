import React, { useEffect } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Box, Grid, Paper, Tooltip, Typography } from '@material-ui/core';
import moment from 'moment';
import { API_URL } from '../../apiTypes';
import { useTranslation } from '../../contexts/translationContext';
import { useHistory, useParams } from 'react-router';
import { Lang } from '../../types/core/lang.types';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { useStyles } from './styles';
import { dateShowFormat } from '../../types/core/date.types';
import { appPaths } from '../../paths';
import { TaskAnswerData } from './ReportTasks';
import photoPlaceholder from '../../photoPlaceholder.png';
import { TaskState } from '../Tasks/types';
import WarningIcon from '@material-ui/icons/Warning';

interface Props {
    tasksAnswerData: TaskAnswerData[];
    actualIndex: number;
    setActualIndex: (index: number) => void;
}

interface ReportParams {
    countryCode: string;
}

export const ReportTasksDetail = ({ tasksAnswerData, actualIndex, setActualIndex }: Props) => {
    const classes = useStyles();
    const history = useHistory();

    const { t, languageList, lang, changeLang } = useTranslation();
    const { countryCode } = useParams<ReportParams>();

    useEffect(() => {
        if (lang?.countryCode === countryCode) return;
        if (languageList) {
            let urlLang = languageList.find(
                (language: Lang) => language.countryCode === countryCode
            );
            if (urlLang) changeLang(urlLang);
        }
    }, []);

    return (
        <Grid item xs={12} style={{ height: '90vh' }}>
            {tasksAnswerData && (
                <Carousel
                    autoPlay={false}
                    animation={'slide'}
                    indicators={true}
                    timeout={500}
                    cycleNavigation
                    stopAutoPlayOnHover={false}
                    navButtonsAlwaysVisible={true}
                    navButtonsProps={{
                        className: classes.carouselNavButtonsDef,
                    }}
                    navButtonsWrapperProps={{
                        className: classes.carouselNavButtonsDefWrapper,
                    }}
                    className={classes.carouselMain}
                    indicatorContainerProps={{
                        style: {
                            marginTop: '-15px',
                        },
                    }}
                    onChange={(e) => setActualIndex(e)}
                >
                    {tasksAnswerData.map((item, index) => {
                        return (
                            <Grid container item spacing={1} style={{ height: '90vh' }} key={index}>
                                <Grid item md={12}>
                                    <Paper variant="outlined" elevation={10}>
                                        <Box mt={1} mb={1} mx={2}>
                                            <Grid container>
                                                <Grid item container md={12} spacing={2}>
                                                    <Grid item md={6}>
                                                        <Typography>
                                                            {t('Report.task.info-top-1')}{' '}
                                                            {item.task.zoneName}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={6}>
                                                        {t('Report.task.info-top-2')}{' '}
                                                        {item.task.vsmName}
                                                    </Grid>
                                                </Grid>
                                                <Grid item container md={12} spacing={2}>
                                                    <Grid item md={6}>
                                                        <Typography>
                                                            {t('Report.task.info-top-3')}{' '}
                                                            {item.task.productionName}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={6}>
                                                        {t('Report.task.info-top-4')}{' '}
                                                        {moment(item.task.created).format(
                                                            dateShowFormat
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item md={12}>
                                    <Paper variant="outlined" elevation={10} key={index}>
                                        <Box p={1} style={{ height: '50vh' }}>
                                            <Carousel
                                                key={index}
                                                autoPlay
                                                interval={10000}
                                                animation={'slide'}
                                                indicators={true}
                                                timeout={500}
                                                navButtonsAlwaysInvisible={
                                                    item?.attachments?.filter((x) =>
                                                        x.contentType.includes('image')
                                                    ).length < 2
                                                }
                                                cycleNavigation
                                                stopAutoPlayOnHover={false}
                                            >
                                                {item?.attachments?.filter((x) =>
                                                    x.contentType.includes('image')
                                                ).length < 1 ? (
                                                    <div
                                                        key={item.task.id}
                                                        onClick={() =>
                                                            history.push(
                                                                `${appPaths.TASKS}/${item.task.id}`
                                                            )
                                                        }
                                                        style={{
                                                            minHeight: '42vh',
                                                            margin: '0 auto',
                                                            background: `url("${photoPlaceholder}") no-repeat center center`,
                                                        }}
                                                    />
                                                ) : (
                                                    item?.attachments
                                                        ?.filter((x) =>
                                                            x.contentType.includes('image')
                                                        )
                                                        .map((att: any, index: number) => (
                                                            <>
                                                                <div
                                                                    key={index}
                                                                    onClick={() =>
                                                                        history.push(
                                                                            `${appPaths.TASKS}/${item.task.id}`
                                                                        )
                                                                    }
                                                                    style={{
                                                                        minHeight: '42vh',
                                                                        margin: '0 auto',
                                                                        background: `url("${API_URL.CHECK_LIST_ANSWER_ATTACHMENT_GET}?id=${att.id}") no-repeat center center`,
                                                                    }}
                                                                />
                                                                <div
                                                                    hidden={
                                                                        item.task.state !==
                                                                        TaskState.DENIED
                                                                    }
                                                                >
                                                                    <CloseIcon
                                                                        style={{
                                                                            color: '#333333',
                                                                            position: 'absolute',
                                                                            fontSize: '8em',
                                                                            top: '60%',
                                                                            left: '55%',
                                                                            zIndex: 1,
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div
                                                                    hidden={
                                                                        item.task.state !==
                                                                        TaskState.FINISHED
                                                                    }
                                                                >
                                                                    <DoneIcon
                                                                        style={{
                                                                            color: '#35dc1f',
                                                                            position: 'absolute',
                                                                            fontSize: '8em',
                                                                            top: '60%',
                                                                            left: '55%',
                                                                            zIndex: 1,
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div
                                                                    hidden={
                                                                        item.task.state ===
                                                                            TaskState.FINISHED ||
                                                                        item.task.state ===
                                                                            TaskState.CREATED ||
                                                                        item.task.state ===
                                                                            TaskState.DENIED
                                                                    }
                                                                >
                                                                    <VpnKeyIcon
                                                                        style={{
                                                                            color: '#FF9900',
                                                                            position: 'absolute',
                                                                            fontSize: '8em',
                                                                            top: '60%',
                                                                            left: '55%',
                                                                            zIndex: 1,
                                                                        }}
                                                                    />
                                                                </div>

                                                                {att.checklistAnswer
                                                                    .isDangerous && (
                                                                    <Grid item md={12}>
                                                                        <Paper
                                                                            variant="outlined"
                                                                            elevation={10}
                                                                        >
                                                                            <Box p={1}>
                                                                                <div>
                                                                                    <div>
                                                                                        <WarningIcon
                                                                                            style={{
                                                                                                color: 'red',
                                                                                                position:
                                                                                                    'absolute',
                                                                                                fontSize:
                                                                                                    '8em',
                                                                                                bottom: '15%',
                                                                                                left: '75%',
                                                                                                zIndex: 1,
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </Box>
                                                                        </Paper>
                                                                    </Grid>
                                                                )}
                                                            </>
                                                        ))
                                                )}
                                            </Carousel>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item md={12} style={{ minHeight: '30vh' }}>
                                    <Paper
                                        variant="outlined"
                                        elevation={10}
                                        style={{ height: '25vh' }}
                                    >
                                        <Tooltip title={item.task?.parentAnswer?.questionText}>
                                            <Typography
                                                variant="h2"
                                                className={classes.questionText}
                                            >
                                                {item.task?.parentAnswer?.questionText}
                                            </Typography>
                                        </Tooltip>
                                        <Box p={1}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    {item.task?.parentAnswer?.answer && (
                                                        <Typography>
                                                            <span className={classes.heading}>
                                                                {t(
                                                                    'Report.info-bottom.label.answer'
                                                                )}{' '}
                                                            </span>
                                                            {item.task?.parentAnswer?.answer ===
                                                            'Yes'
                                                                ? t(
                                                                      'Audit.layout.answer-option-yes'
                                                                  )
                                                                : t(
                                                                      'Audit.layout.answer-option-no'
                                                                  )}
                                                        </Typography>
                                                    )}
                                                    {item.task?.parentAnswer?.comment && (
                                                        <Typography>
                                                            <span className={classes.heading}>
                                                                {t(
                                                                    'Report.info-bottom.label.comment'
                                                                )}{' '}
                                                            </span>
                                                            {item.task?.parentAnswer?.comment}
                                                        </Typography>
                                                    )}
                                                    {item.task.actionSolvedDate !== null &&
                                                    item.task.solverName ? (
                                                        <Typography>
                                                            <span className={classes.heading}>
                                                                {t(
                                                                    'Report.info-bottom.label.solver-name-finished'
                                                                )}{' '}
                                                            </span>
                                                            {item.task.solverName}
                                                        </Typography>
                                                    ) : (
                                                        <Typography>
                                                            <span className={classes.heading}>
                                                                {t(
                                                                    'Report.info-bottom.label.solver-name'
                                                                )}{' '}
                                                            </span>
                                                            {item.task.solverName}
                                                        </Typography>
                                                    )}
                                                    {item.task.auditTypeDangerousFindings && (
                                                        <Typography>
                                                            <span className={classes.heading}>
                                                                {t(
                                                                    'Report.info-bottom.label.safety-impact'
                                                                )}{' '}
                                                            </span>
                                                            {item.task.safetyImpact
                                                                ? item.task.safetyImpact
                                                                : '-'}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                                <Grid item xs={6}>
                                                    {item.task.actionSolvedDate !== null ? (
                                                        <Typography>
                                                            <span className={classes.heading}>
                                                                {t(
                                                                    'Report.info-bottom.label.solved-date'
                                                                )}
                                                            </span>
                                                            <span>
                                                                {moment(item.task.actionSolvedDate)
                                                                    .local()
                                                                    .format(dateShowFormat)}
                                                            </span>
                                                        </Typography>
                                                    ) : item.task.plannedDate ? (
                                                        moment(item.task.plannedDate).isBefore(
                                                            Date()
                                                        ) ? (
                                                            <Typography>
                                                                <span className={classes.heading}>
                                                                    {t(
                                                                        'Report.info-bottom.label.solved-date-planned'
                                                                    )}{' '}
                                                                </span>
                                                                <span style={{ color: '#FF0000' }}>
                                                                    {moment(item.task.plannedDate)
                                                                        .local()
                                                                        .format(dateShowFormat)}
                                                                </span>
                                                            </Typography>
                                                        ) : (
                                                            <Typography>
                                                                <span className={classes.heading}>
                                                                    {t(
                                                                        'Report.info-bottom.label.solved-date-planned'
                                                                    )}{' '}
                                                                </span>
                                                                {moment(item.task.plannedDate)
                                                                    .local()
                                                                    .format(dateShowFormat)}
                                                            </Typography>
                                                        )
                                                    ) : (
                                                        ''
                                                    )}
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                {item.task.plannedActionDescription &&
                                                    !item.task.actionSolvedDescription && (
                                                        <>
                                                            <Typography>
                                                                <span className={classes.heading}>
                                                                    {t(
                                                                        'Report.info-bottom.label.solution-description-planned'
                                                                    )}{' '}
                                                                </span>
                                                                <Tooltip
                                                                    title={
                                                                        <span
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: item.task
                                                                                    .plannedActionDescription,
                                                                            }}
                                                                        />
                                                                    }
                                                                >
                                                                    <span
                                                                        className={
                                                                            classes.truncateText
                                                                        }
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: item.task
                                                                                .plannedActionDescription,
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            </Typography>
                                                        </>
                                                    )}
                                                {item.task.actionSolvedDescription && (
                                                    <>
                                                        <Typography>
                                                            <span className={classes.heading}>
                                                                {t(
                                                                    'Report.info-bottom.label.solution-description'
                                                                )}{' '}
                                                            </span>
                                                            <Tooltip
                                                                title={
                                                                    <span
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: item.task
                                                                                .actionSolvedDescription,
                                                                        }}
                                                                    />
                                                                }
                                                            >
                                                                <span
                                                                    className={classes.truncateText}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: item.task
                                                                            .actionSolvedDescription,
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        </Typography>
                                                    </>
                                                )}
                                            </Grid>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Carousel>
            )}
        </Grid>
    );
};
