import React, { useCallback, useEffect, useState } from 'react';
import useSWR from 'swr';
import { TasksApiResponse } from './types';
import { fetcher } from '../../apiUtils';
import { ErrorPage } from '../../components/Snackbar';
import { API_URL } from '../../apiTypes';
import { PageWrapper } from '../../containers/PageWrapper';
import { HeaderPage } from '../../containers/HeaderPage/HeaderPage';
import { useStepNames } from '../../components/Breadcrumbs/useStepNames';
import { useHistory } from 'react-router';
import { appPaths } from '../../paths';
import { Chip, Grid, TableCell, Theme, useMediaQuery } from '@material-ui/core';
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Paper from '@material-ui/core/Paper';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from '../../contexts/translationContext';
import moment from 'moment';
import { SESSION_KEYS } from '../../sessionStorageKeys';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport
                csvOptions={{
                    fileName: `userTasks_${moment().format('YYYYMMDD')}`,
                    delimiter: ';',
                    utf8WithBom: true,
                }}
            />
        </GridToolbarContainer>
    );
}

export const TasksList = () => {
    const datagridId = 'tasks';
    const [sortModel, setSortModel] = useState();
    const [filterModel, setFilterModel] = useState();
    const storageOnlyOpened = window.sessionStorage.getItem(
        `${SESSION_KEYS.FILTER_TASKS_ONLY_OPENED}`
    );
    const storageIsForCurrentUser = window.sessionStorage.getItem(
        `${SESSION_KEYS.FILTER_TASKS_FOR_CURRENT_USER}`
    );

    const { t } = useTranslation();
    const stepNames = useStepNames();
    const history = useHistory();
    const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [isForCurrentUser, setIsForCurrentUser] = useState(
        storageIsForCurrentUser !== null && storageIsForCurrentUser === 'true' ? true : false
    );
    const [isOnlyOpened, setIsOnlyOpened] = useState(
        storageOnlyOpened !== null && storageOnlyOpened === 'false' ? false : true
    );
    const { data, error, mutate } = useSWR<TasksApiResponse>(
        `${API_URL.USER_TASK_GET_LIST_FILTERED}?forCurrentUser=${isForCurrentUser}&openedOnly=${isOnlyOpened}`,
        fetcher
    );

    const Columns = [
        { field: 'customId', headerName: t('Tasks.table-col-1'), flex: 0.2, minWidth: 150 },
        {
            field: 'id',
            headerName: t('Tasks.table-col-isDangerous'),
            flex: 0.2,
            minWidth: 150,
            renderCell: (e: any) => {
                if (!e.row.id) return;
                if (e.row.parentAnswer.isDangerous)
                    return (
                        <WarningIcon
                            style={{
                                color: 'red',
                                fontSize: '35px',
                            }}
                        />
                    );
                return <></>;
            },
            valueGetter: (e: any) => {
                if (!e.row.id) return;
                return e.row.parentAnswer.isDangerous ? 'Yes' : '';
            },
        },
        { field: 'auditType', headerName: t('Tasks.table-col-2'), flex: 0.2, minWidth: 180 },
        { field: 'vsmName', headerName: t('Tasks.table-col-3'), flex: 0.2, minWidth: 180 },
        { field: 'productionName', headerName: t('Tasks.table-col-4'), flex: 0.3, minWidth: 180 },
        { field: 'zoneName', headerName: t('Tasks.table-col-5'), flex: 0.3, minWidth: 180 },
        { field: 'solverName', headerName: t('Tasks.table-col-6'), flex: 0.3, minWidth: 180 },
        { field: 'state', headerName: t('Tasks.table-col-7'), flex: 0.3, minWidth: 180 },
        {
            field: ' ',
            headerName: ' ',
            flex: 0.1,
            sortable: false,
            disableColumnFilter: true,
            disableColumnMenu: true,

            renderCell: (params: any) => {
                return (
                    <>
                        <Tooltip title={t('Detail')}>
                            <IconButton onClick={() => handleDetailItem(params)}>
                                <ArrowForwardIosIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    const handleDetailItem = ({ id }) => history.push(`${appPaths.TASKS}/${id}`);

    useEffect(() => {
        // Sort
        const storageSortModel = window.sessionStorage.getItem(
            `${SESSION_KEYS.DATAGRID_SORT}${datagridId}`
        );
        if (storageSortModel) setSortModel(JSON.parse(storageSortModel));

        // Filter
        const storageFilterModel = window.sessionStorage.getItem(
            `${SESSION_KEYS.DATAGRID_FILTER}${datagridId}`
        );
        if (storageFilterModel) setFilterModel(JSON.parse(storageFilterModel));
    }, []);

    const saveSortModel: any = useCallback((model: any) => {
        window.sessionStorage.setItem(
            `${SESSION_KEYS.DATAGRID_SORT}${datagridId}`,
            JSON.stringify(model)
        );
        setSortModel(model);
    }, []);

    const saveFilterModel: any = useCallback((model: any) => {
        window.sessionStorage.setItem(
            `${SESSION_KEYS.DATAGRID_FILTER}${datagridId}`,
            JSON.stringify(model)
        );
        setFilterModel(model);
    }, []);

    if (error) return <ErrorPage error={error} />;
    if (!data) return <Loader />;

    return (
        <PageWrapper loading={!data}>
            <HeaderPage stepName={t('Tasks.main-headline')} heading={t('Tasks.main-headline')} />
            <Chip
                style={{ margin: 5 }}
                color="primary"
                variant={isForCurrentUser ? 'default' : 'outlined'}
                onClick={() => {
                    setIsForCurrentUser(!isForCurrentUser);
                    window.sessionStorage.setItem(
                        `${SESSION_KEYS.FILTER_TASKS_FOR_CURRENT_USER}`,
                        (!isForCurrentUser as boolean).toString()
                    );
                }}
                label={t('Tasks.table-filter-1')}
            />

            <Chip
                style={{ margin: 5 }}
                color="primary"
                variant={isOnlyOpened ? 'default' : 'outlined'}
                onClick={() => {
                    setIsOnlyOpened(!isOnlyOpened);
                    window.sessionStorage.setItem(
                        `${SESSION_KEYS.FILTER_TASKS_ONLY_OPENED}`,
                        (!isOnlyOpened as boolean).toString()
                    );
                }}
                label={t('Tasks.table-filter-2')}
            />
            {/* <StickyHeadTable columns={Columns()} rows={data} handleDetailItem={handleDetailItem} /> */}
            <Grid item container xs={12} sm={12} md={12}>
                <div style={{ minWidth: isMobileView ? '98vw' : '100%' }}>
                    <Paper>
                        <DataGridPro
                            autoHeight
                            rows={data}
                            columns={Columns}
                            onRowDoubleClick={handleDetailItem}
                            pageSize={20}
                            rowsPerPageOptions={[20]}
                            density="compact"
                            disableSelectionOnClick
                            components={{ Toolbar: CustomToolbar }}
                            sortModel={sortModel}
                            filterModel={filterModel}
                            onSortModelChange={(e) => {
                                if (e.length !== 0) saveSortModel(e);
                            }}
                            onFilterModelChange={(e) => {
                                saveFilterModel(e);
                            }}
                        />
                    </Paper>
                </div>
            </Grid>
        </PageWrapper>
    );
};
