import * as yup from 'yup';

export const cFieldNames = {
    improvementProposalName: 'improvementProposalName',
    improvementProposalCurrentState: 'improvementProposalCurrentState',
    improvementProposalFutureState: 'improvementProposalFutureState',
    improvementProposalBenefits: 'improvementProposalBenefits',
};

export const validationSchema = (t: any) => {
    return yup.object().shape({
        [cFieldNames.improvementProposalName]: yup.string().required(),
        [cFieldNames.improvementProposalCurrentState]: yup.string().required(),
        [cFieldNames.improvementProposalFutureState]: yup.string().required(),
        [cFieldNames.improvementProposalBenefits]: yup.string().required(),
    });
};
