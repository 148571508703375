import React, { useCallback, useRef, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Footer } from '../Common/Layout/Footer';
import { Button } from 'reactstrap';
import '../Common/audit.scss';
import { useTranslation } from '../../../contexts/translationContext';
import { useStyles } from '../Common/styles';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import ClearIcon from '@material-ui/icons/Clear';
import 'react-html5-camera-photo/build/css/index.css';
import Grid from '@mui/material/Grid';
import { appPaths } from '../../../paths';
import { useHistory } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { Header } from '../Common/Layout/Header';
import { Loader } from '../../../components/Loader/Loader';
import { API_URL } from '../../../apiTypes';
import { postApi, postApiFormData } from '../../../apiUtils';
import FieldText from '../../../components/Form/FieldText/FieldText';
import { cFieldNames, validationSchema } from './schema';

interface Props {
    selectedAudit: any;
    auditorNetId: string;
}

export const QuestionsLayout_6 = ({ selectedAudit, auditorNetId }: Props) => {
    // *** External hooks ***
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();

    // *** Local states ***
    const [takingPicture, setTakingPicture] = useState(false);
    const [uploadingFiles, setUploadingFiles] = useState(false);
    const [pictures, setPictures] = useState<any[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);
    const [isLoading, setLoading] = useState(false);

    // *** Local functions ***
    const reloadCamera = useCallback(() => {
        setTakingPicture(false);
        setTimeout(function () {
            setTakingPicture(true);
        }, 500);
    }, []);

    const handleTakePhoto = useCallback(
        (dataUri: any) => {
            setPictures([dataUri, ...pictures]);
        },
        [pictures]
    );

    const createCheckList = useCallback(async () => {
        return postApi({
            url: API_URL.CHECK_LIST_CREATE,
            data: {
                auditId: selectedAudit.auditId,
                auditorNetId: auditorNetId,
                auditLevel: 'Level1',
            },
        });
    }, []);

    const createAnswer = useCallback(async (checkListId: number) => {
        return postApi({
            url: API_URL.CHECK_LIST_ANSWER_CREATE,
            data: {
                checklistId: checkListId,
                ordering: 0,
                questionText: 'None',
                correctAnswer: 'None',
                answer: 'None',
                comment: null,
                isDangerous: false,
            },
        });
    }, []);

    const createAnswerPictures = useCallback(async (answerId: number, picture: any) => {
        const formData = new FormData();
        let blob = await fetch(picture).then((res) => res.blob());
        const myFile = new File([blob], 'image.png', {
            type: blob.type,
        });
        formData.append('file', myFile);

        return postApiFormData({
            url: `${API_URL.CHECK_LIST_ANSWER_ATTACHMENT_UPLOAD}?answerId=${answerId}`,
            data: formData,
        });
    }, []);

    const createAnswerAttachements = useCallback(async (answerId: number, file: any) => {
        const formData = new FormData();
        formData.append('file', file);
        return postApiFormData({
            url: `${API_URL.CHECK_LIST_ANSWER_ATTACHMENT_UPLOAD}?answerId=${answerId}`,
            data: formData,
        });
    }, []);

    const setCheckListFinished = useCallback(
        async (checkListId: number, formData: any, callAfterSuccess: any, callAfterError: any) => {
            return postApi({
                url: `${API_URL.CHECK_LIST_IS_FINISHED_LAYOUT_6}`,
                data: { checklistId: checkListId, ...formData },
                callAfterSuccess,
                callAfterError,
            });
        },
        []
    );

    const handleFormSubmit = useCallback(
        async (formData) => {
            setLoading(true);

            const resultCheckList: any = await createCheckList();
            if (!resultCheckList) {
                setLoading(false);
                return;
            }

            const resultAnswer: any = await createAnswer(resultCheckList.id);
            if (!resultAnswer) {
                setLoading(false);
                return;
            }

            const resultPictuires: any = await Promise.all(
                pictures.map(async (picture: any) => {
                    await createAnswerPictures(resultAnswer.id, picture);
                })
            );

            if (!resultPictuires) {
                setLoading(false);
                return;
            }

            if (selectedFiles) {
                const resultAttachements: any = await Promise.all(
                    selectedFiles.map(async (file: any) => {
                        await createAnswerAttachements(resultAnswer.id, file);
                    })
                );

                if (!resultAttachements) {
                    setLoading(false);
                    return;
                }
            }

            await setCheckListFinished(
                resultCheckList.id,
                formData,
                () => {
                    history.push(appPaths.AUDIT);
                },
                () => {
                    setLoading(false);
                }
            );
        },
        [pictures, selectedFiles]
    );

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (!event.target.files) return;

        // @ts-ignore
        if (selectedFiles) setSelectedFiles([...selectedFiles, ...event.target.files]);
        // @ts-ignore
        else setSelectedFiles([...event.target.files]);

        setUploadingFiles(false);
    };

    return (
        <Box>
            <Header paddingTop={15} paddingBottom={15}>
                <Typography variant="h1" align="center" className={classes.labelText}>
                    {selectedAudit.auditType.name}
                </Typography>
            </Header>
            {!isLoading && (
                <Formik
                    initialValues={{
                        improvementProposalName: '',
                        improvementProposalCurrentState: '',
                        improvementProposalFutureState: '',
                        improvementProposalBenefits: '',
                    }}
                    validationSchema={validationSchema(t)}
                    onSubmit={handleFormSubmit}
                >
                    {({ handleChange, handleSubmit, isValid, isValidating, dirty }) => (
                        <Form onSubmit={handleSubmit} onChange={handleChange}>
                            <Grid
                                container
                                alignContent={'center'}
                                alignItems={'center'}
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                {takingPicture && (
                                    <Grid item xs={12}>
                                        <Camera
                                            onTakePhoto={(dataUri) => handleTakePhoto(dataUri)}
                                            idealFacingMode={FACING_MODES.ENVIRONMENT}
                                            isImageMirror={false}
                                        />
                                        <Button
                                            onClick={() => reloadCamera()}
                                            variant="contained"
                                            className={classes.formButton}
                                            style={{ margin: 5, width: 'fit-content' }}
                                        >
                                            {t('Audit.layout.btn-reload-camera')}
                                        </Button>
                                        <Button
                                            onClick={() => setTakingPicture(false)}
                                            variant="contained"
                                            className={classes.formButton}
                                            style={{ margin: 5, width: 'fit-content' }}
                                        >
                                            {t('Audit.layout.btn-close-camera')}
                                        </Button>
                                    </Grid>
                                )}
                                {uploadingFiles && (
                                    <Grid item xs={12}>
                                        <div
                                            style={{
                                                backgroundColor: 'white',
                                                padding: 10,
                                                marginBottom: 10,
                                            }}
                                        >
                                            <input
                                                type="file"
                                                title={t('Audit.layout.input-upload-file')}
                                                onChange={handleFileUpload}
                                                multiple
                                            />
                                        </div>
                                        <Button
                                            onClick={() => setUploadingFiles(false)}
                                            variant="contained"
                                            className={classes.formButton}
                                            style={{ margin: 5, width: 'fit-content' }}
                                        >
                                            {t('Audit.layout.btn-close-file-upload')}
                                        </Button>
                                    </Grid>
                                )}

                                {!takingPicture && !uploadingFiles && (
                                    <>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                padding: '5px 10px 5px 10px',
                                                textAlign: 'left',
                                            }}
                                        >
                                            <label
                                                className={classes.formLabel}
                                                htmlFor={cFieldNames.improvementProposalName}
                                            >
                                                {t('Audit.layout.input-improvementProposalName')}
                                                <span style={{ color: 'red' }}>*</span>
                                            </label>
                                            <Field
                                                name={cFieldNames.improvementProposalName}
                                                placeholder={t(
                                                    'Audit.layout.input-improvementProposalName-placeholder'
                                                )}
                                                rows={1}
                                                component={'textarea'}
                                                style={{ width: '100%' }}
                                                maxLength={50}
                                                required
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                padding: '5px 10px 5px 10px',
                                                textAlign: 'left',
                                            }}
                                        >
                                            <label
                                                className={classes.formLabel}
                                                htmlFor={
                                                    cFieldNames.improvementProposalCurrentState
                                                }
                                            >
                                                {t(
                                                    'Audit.layout.input-improvementProposalCurrentState'
                                                )}
                                                <span style={{ color: 'red' }}>*</span>
                                            </label>
                                            <Field
                                                name={cFieldNames.improvementProposalCurrentState}
                                                placeholder={t(
                                                    'Audit.layout.input-improvementProposalCurrentState-placeholder'
                                                )}
                                                rows={3}
                                                component={'textarea'}
                                                style={{ width: '100%' }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                padding: '5px 10px 5px 10px',
                                                textAlign: 'left',
                                            }}
                                        >
                                            <label
                                                className={classes.formLabel}
                                                htmlFor={cFieldNames.improvementProposalFutureState}
                                            >
                                                {t(
                                                    'Audit.layout.input-improvementProposalFutureState'
                                                )}
                                                <span style={{ color: 'red' }}>*</span>
                                            </label>
                                            <Field
                                                name={cFieldNames.improvementProposalFutureState}
                                                placeholder={t(
                                                    'Audit.layout.input-improvementProposalFutureState-placeholder'
                                                )}
                                                rows={3}
                                                component={'textarea'}
                                                style={{ width: '100%' }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                padding: '5px 10px 5px 10px',
                                                textAlign: 'left',
                                            }}
                                        >
                                            <label
                                                className={classes.formLabel}
                                                htmlFor={cFieldNames.improvementProposalBenefits}
                                            >
                                                {t(
                                                    'Audit.layout.input-improvementProposalBenefits'
                                                )}
                                                <span style={{ color: 'red' }}>*</span>
                                            </label>
                                            <Field
                                                name={cFieldNames.improvementProposalBenefits}
                                                placeholder={t(
                                                    'Audit.layout.input-improvementProposalBenefits-placeholder'
                                                )}
                                                rows={3}
                                                component={'textarea'}
                                                style={{ width: '100%' }}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {!takingPicture && !uploadingFiles && (
                                    <>
                                        <Grid item xs={6}>
                                            <Button
                                                onClick={() => setTakingPicture(true)}
                                                variant="contained"
                                                className={classes.formButton}
                                            >
                                                {t('Audit.layout.btn-create-photo')}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                onClick={() => setUploadingFiles(true)}
                                                variant="contained"
                                                className={classes.formButton}
                                            >
                                                {t('Audit.layout.btn-upload-file')}
                                            </Button>
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={12} style={{ marginTop: 10 }}>
                                    <div
                                        style={{
                                            width: '90vw',
                                            margin: '0 auto',
                                            whiteSpace: 'nowrap',
                                            overflowX: 'auto',
                                        }}
                                    >
                                        <div style={{ width: '100%' }}>
                                            <div>
                                                {pictures.map((image: any, index: number) => (
                                                    <div className={classes.image} key={index}>
                                                        <ClearIcon
                                                            className={classes.btnDelete}
                                                            onClick={() => {
                                                                setPictures([
                                                                    ...pictures.slice(0, index),
                                                                    ...pictures.slice(
                                                                        index + 1,
                                                                        pictures.length
                                                                    ),
                                                                ]);
                                                            }}
                                                        />
                                                        <img
                                                            className={classes.imgPreview}
                                                            src={image}
                                                        />
                                                    </div>
                                                ))}
                                                {selectedFiles && (
                                                    <div className={classes.filesContainer}>
                                                        {selectedFiles.map(
                                                            (file: File, index: number) => {
                                                                return (
                                                                    <div
                                                                        className={classes.file}
                                                                        key={index}
                                                                    >
                                                                        <div
                                                                            className={
                                                                                classes.fileDelete
                                                                            }
                                                                        >
                                                                            <ClearIcon
                                                                                className={
                                                                                    classes.btnDeleteFile
                                                                                }
                                                                                onClick={() => {
                                                                                    setSelectedFiles(
                                                                                        [
                                                                                            ...selectedFiles.slice(
                                                                                                0,
                                                                                                index
                                                                                            ),
                                                                                            ...selectedFiles.slice(
                                                                                                index +
                                                                                                    1,
                                                                                                selectedFiles.length
                                                                                            ),
                                                                                        ]
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div>{file.name}</div>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>

                            {!takingPicture && !uploadingFiles && (
                                <Footer>
                                    <Box sx={{ display: 'flex' }}>
                                        <Button
                                            onClick={() => history.push(appPaths.AUDIT)}
                                            variant="contained"
                                            className={classes.navButton}
                                            type="submit"
                                        >
                                            {t('Storno')}
                                        </Button>
                                        <Button
                                            variant="outline"
                                            className={classes.navButton}
                                            disabled={!isValid || !dirty}
                                            type="submit"
                                        >
                                            {t('Save-data')}
                                        </Button>
                                    </Box>
                                </Footer>
                            )}
                        </Form>
                    )}
                </Formik>
            )}

            {isLoading && <Loader />}
        </Box>
    );
};
