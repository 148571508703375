import React from 'react';
import { AnswerAttachment } from '../../types/ChecklistAnswerAttachmentApiResponse';
import { API_URL } from '../../apiTypes';

interface Props {
    files: AnswerAttachment[];
}

export const TaskFiles = ({ files }: Props) => {
    const downloadFile = (fileUrl: string, fileName: string) => {
        // Create a new link
        const anchor = document.createElement('a');
        anchor.href = fileUrl;
        anchor.download = fileName;
        // Append to the DOM
        document.body.appendChild(anchor);
        // Trigger `click` event
        anchor.click();
        // Remove element from DOM
        document.body.removeChild(anchor);
    };

    const handleDownloadItem = (item: any) => {
        fetch(API_URL.CHECK_LIST_ANSWER_ATTACHMENT_GET + `?id=${item.id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => response.blob())
            .then((data) => {
                const fileUrl: string = URL.createObjectURL(data);
                downloadFile(fileUrl, item.name);
            });
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '15px' }}>
            {files.map((file, i) => {
                return (
                    <div
                        key={i}
                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => handleDownloadItem(file)}
                    >
                        {file.name}
                    </div>
                );
            })}
        </div>
    );
};
