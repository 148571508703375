import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import { useTranslation } from '../../../../contexts/translationContext';

interface Props {
    title: string;
}

export const QuestionType = ({ title }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Typography variant="h2" className={classes.questionType}>
            {t('Audit.layout.question-type')}: {title}
        </Typography>
    );
};
