export enum AuditLayoutId {
    Layout1 = 1,
    Layout2 = 2,
    Layout3 = 3,
    Layout4 = 4,
    Layout5 = 5,
    Layout6 = 6,
}

export const getAuditById = (layoutId: number) => {
    switch (layoutId) {
        case 1:
            return AuditLayoutId.Layout1;
        case 2:
            return AuditLayoutId.Layout2;
        case 3:
            return AuditLayoutId.Layout3;
        case 4:
            return AuditLayoutId.Layout4;
        case 5:
            return AuditLayoutId.Layout5;
        case 6:
            return AuditLayoutId.Layout6;
    }
};
