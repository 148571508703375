import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Alert, Button } from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import { AuditQuestion } from '../../types/AuditQuestionApiResponse';
import { cFieldNames, validationSchema } from './schema';
import { useStyles } from '../../pages/AuditSurvey/Common/styles';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import ClearIcon from '@material-ui/icons/Clear';
import { AuditQuestionAnswer } from '../../types/AuditQuestionAnswerApiResponse';
import InfoIcon from '@material-ui/icons/Info';
import { DescriptionModal } from '../../pages/AuditSurvey/Common/DescriptionModal';
import { appPaths } from '../../paths';
import { useHistory } from 'react-router';
import { useAuditBtnPos } from '../../hooks/useAuditBtnPos';
import { useTranslation } from '../../contexts/translationContext';
import { Content } from '../../pages/AuditSurvey/Common/Layout/Content';
import { Footer } from '../../pages/AuditSurvey/Common/Layout/Footer';
import { QuestionType } from '../../pages/AuditSurvey/Common/Questions/QuestionType';

interface Props {
    question: AuditQuestion;
    questionsAnswers: AuditQuestionAnswer[];
    onSubmit: (answer: AuditQuestionAnswer, isDangerous: boolean, comment?: string) => void;
    handleCreateAttachments: any;
    takingPicture: boolean;
    setTakingPicture: any;
    setOpenAlert: any;
    dangerousFindings: boolean;
}

const initialValues: any = {
    comment: '',
};

export const QuestionFormL3 = ({
    question,
    questionsAnswers,
    onSubmit,
    handleCreateAttachments,
    takingPicture,
    setTakingPicture,
    setOpenAlert,
    dangerousFindings,
}: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [openDescription, setOpenDescription] = useState<boolean>(false);
    const [modalText, setModalText] = useState<string>('');
    const [photoRequiredError, setPhotoRequiredError] = useState<boolean>();
    const [commentRequiredError, setCommentRequiredError] = useState<boolean>();
    const [imageCapture, setImageCapture] = useState<any[]>([]);
    const [tmpAnswer, setTmpAnswer] = useState<AuditQuestionAnswer>();
    const [isDangerous, setIsDangerous] = useState<boolean>(false);
    // File upload
    const [uploadingFiles, setUploadingFiles] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);

    // States pro nastavení pozice buttonů
    const lastButtonRef = useRef<any>(null);
    const { buttonsContainerStyle, setButtonsContainerStyle, setCalcButtonsPos } = useAuditBtnPos({
        lastElementRef: lastButtonRef,
    });

    const handleOpenModal = (description: string | null) => {
        if (description) {
            setModalText(description);
            setOpenDescription(true);
        }
    };

    const handleCloseModal = () => {
        setModalText('');
        setOpenDescription(false);
    };

    const handleTakePhoto = (dataUri) => {
        setImageCapture([dataUri, ...imageCapture]);
        setTakingPicture(false);
    };
    useEffect(() => {
        if (!question.photoRequiredForWrongAnswer) return;
        if (imageCapture.length > 0) setPhotoRequiredError(false);
    }, [imageCapture]);

    const handleFormSubmit = (formData) => {
        let isError = false;
        // Je fotografie povinná? Pokud není vybrána, zobrazíme error
        if (question.photoRequiredForWrongAnswer && imageCapture.length < 1) {
            isError = true;
            setPhotoRequiredError(true);
        }

        // Komentář je povinný
        /* if (formData.comment === '') {
            isError = true;
            setCommentRequiredError(true);
        } */

        if (isError) return;

        // Validace je ok. Nastavíme přílohy, potvrdíme.
        if (tmpAnswer) {
            if (selectedFiles) handleCreateAttachments(imageCapture, undefined, selectedFiles);
            else handleCreateAttachments(imageCapture);

            setImageCapture([]);
            setSelectedFiles([]);
            setTakingPicture(false);
            onSubmit(tmpAnswer, isDangerous, formData.comment);
            if (formData.comment) formData.comment = '';
            setTmpAnswer(undefined);
        }
    };

    // reload kamery - po vypnutí browseru telefonu a znovu zapnutí je občas černá obrazovka
    const reloadCamera = () => {
        setTakingPicture(false);
        setTimeout(function () {
            setTakingPicture(true);
        }, 500);
    };

    // Při načtení otázky nastaví pozici spodních buttonů na automatickou tzn. zařadí se před předchozí obsah
    // Nastaví se příznak, že se má přepočítat pozice buttonů
    useEffect(() => {
        setButtonsContainerStyle({});
        setTimeout(() => {
            setCalcButtonsPos(true);
        }, 10);
    }, [question, photoRequiredError, commentRequiredError, imageCapture]);

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (!event.target.files) return;

        // @ts-ignore
        if (selectedFiles) setSelectedFiles([...selectedFiles, ...event.target.files]);
        // @ts-ignore
        else setSelectedFiles([...event.target.files]);

        setUploadingFiles(false);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema(t)}
            onSubmit={handleFormSubmit}
        >
            {({ handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit} onChange={handleChange}>
                    <Content bottomOffset={60}>
                        <Grid
                            container
                            alignContent={'center'}
                            alignItems={'center'}
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            {!takingPicture && !uploadingFiles && (
                                <>
                                    <Grid item xs={12}>
                                        {question?.typeName && (
                                            <QuestionType title={question?.typeName} />
                                        )}
                                        <Typography
                                            variant="body1"
                                            className={classes.questionText}
                                        >
                                            {question?.questionText}
                                        </Typography>
                                        {question.description !== null && (
                                            <>
                                                <InfoIcon
                                                    className={classes.questionIcon}
                                                    onClick={() =>
                                                        handleOpenModal(question.description)
                                                    }
                                                />
                                            </>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {questionsAnswers.map(
                                            (answer: AuditQuestionAnswer, index: number) =>
                                                answer.auditQuestionId === question.id && (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        key={index}
                                                        className={
                                                            tmpAnswer?.id === answer.id
                                                                ? classes.answerContainerActive
                                                                : classes.answerContainer
                                                        }
                                                        onClick={() => setTmpAnswer(answer)}
                                                    >
                                                        <Typography
                                                            variant="body1"
                                                            className={classes.answerLabel}
                                                        >
                                                            {answer.answerText}
                                                        </Typography>
                                                        {answer.answerDescription && (
                                                            <>
                                                                <InfoIcon
                                                                    className={
                                                                        tmpAnswer?.id === answer.id
                                                                            ? classes.questionIconActive
                                                                            : classes.questionIcon
                                                                    }
                                                                    onClick={() =>
                                                                        handleOpenModal(
                                                                            answer.answerDescription
                                                                        )
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    </Grid>
                                                )
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        style={{ marginTop: '10px' }}
                                    >
                                        <Field
                                            name={cFieldNames.comment}
                                            label={t('Audit.layout.input-comment')}
                                            placeholder={t('Audit.layout.input-comment')}
                                            rows={3}
                                            onChange={(c: any) =>
                                                c.target.value.length > 0
                                                    ? setCommentRequiredError(false)
                                                    : setCommentRequiredError(true)
                                            }
                                            component={'textarea'}
                                            style={{ width: '100%' }}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12}>
                                {!takingPicture && !uploadingFiles && (
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Button
                                                onClick={() => setTakingPicture(true)}
                                                variant="contained"
                                                className={classes.formButton}
                                            >
                                                {t('Audit.layout.btn-create-photo')}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                onClick={() => setUploadingFiles(true)}
                                                variant="contained"
                                                className={classes.formButton}
                                            >
                                                {t('Audit.layout.btn-upload-file')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                                {takingPicture && (
                                    <>
                                        <Camera
                                            onTakePhoto={(dataUri) => {
                                                handleTakePhoto(dataUri);
                                            }}
                                            idealFacingMode={FACING_MODES.ENVIRONMENT}
                                            isImageMirror={false}
                                        />
                                        <Button
                                            onClick={() => reloadCamera()}
                                            variant="contained"
                                            className={classes.formButton}
                                            style={{ margin: 5, width: 'fit-content' }}
                                        >
                                            {t('Audit.layout.btn-reload-camera')}
                                        </Button>
                                    </>
                                )}
                                {uploadingFiles && (
                                    <Grid item xs={12}>
                                        <div
                                            style={{
                                                backgroundColor: 'white',
                                                padding: 10,
                                                marginBottom: 10,
                                            }}
                                        >
                                            <input
                                                type="file"
                                                title={t('Audit.layout.input-upload-file')}
                                                onChange={handleFileUpload}
                                                multiple
                                            />
                                        </div>
                                        <Button
                                            onClick={() => setUploadingFiles(false)}
                                            variant="contained"
                                            className={classes.formButton}
                                            style={{ margin: 5, width: 'fit-content' }}
                                        >
                                            {t('Audit.layout.btn-close-file-upload')}
                                        </Button>
                                    </Grid>
                                )}
                                <div
                                    style={{
                                        width: '90vw',
                                        margin: '0 auto',
                                        whiteSpace: 'nowrap',
                                        overflowX: 'auto',
                                    }}
                                >
                                    <div style={{ width: '100%' }}>
                                        <div>
                                            {imageCapture.map((image: any, index: number) => (
                                                <div className={classes.image} key={index}>
                                                    <ClearIcon
                                                        className={classes.btnDelete}
                                                        onClick={() => {
                                                            setImageCapture([
                                                                ...imageCapture.slice(0, index),
                                                                ...imageCapture.slice(
                                                                    index + 1,
                                                                    imageCapture.length
                                                                ),
                                                            ]);
                                                        }}
                                                    />
                                                    <img
                                                        className={classes.imgPreview}
                                                        src={image}
                                                    />
                                                </div>
                                            ))}
                                            {selectedFiles && (
                                                <div className={classes.filesContainer}>
                                                    {selectedFiles.map(
                                                        (file: File, index: number) => {
                                                            return (
                                                                <div
                                                                    className={classes.file}
                                                                    key={index}
                                                                >
                                                                    <div
                                                                        className={
                                                                            classes.fileDelete
                                                                        }
                                                                    >
                                                                        <ClearIcon
                                                                            className={
                                                                                classes.btnDeleteFile
                                                                            }
                                                                            onClick={() => {
                                                                                setSelectedFiles([
                                                                                    ...selectedFiles.slice(
                                                                                        0,
                                                                                        index
                                                                                    ),
                                                                                    ...selectedFiles.slice(
                                                                                        index + 1,
                                                                                        selectedFiles.length
                                                                                    ),
                                                                                ]);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div>{file.name}</div>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            {photoRequiredError && !takingPicture && (
                                <Grid item xs={12}>
                                    <Alert severity="error">
                                        {t('Audit.validation.photo-required')}
                                    </Alert>
                                </Grid>
                            )}
                            {commentRequiredError && !takingPicture && (
                                <Grid item xs={12}>
                                    <Alert severity="error">
                                        {t('Audit.validation.comment-required')}
                                    </Alert>
                                </Grid>
                            )}
                        </Grid>
                    </Content>
                    <Footer>
                        {dangerousFindings &&
                        !takingPicture &&
                        imageCapture.length > 0 &&
                        !uploadingFiles ? (
                            <>
                                <Box sx={{ display: 'flex', marginBottom: 20 }}>
                                    <Button
                                        variant="outline"
                                        className={classes.navButton}
                                        onClick={() => setOpenAlert(true)}
                                    >
                                        {t('Storno')}
                                    </Button>
                                </Box>
                                <Box sx={{ display: 'flex' }}>
                                    <Button
                                        variant="outline"
                                        className={classes.navButton}
                                        type="submit"
                                        onClick={() => {
                                            setIsDangerous(false);
                                        }}
                                    >
                                        {t('Audit.layout.L4.next-finding-not-dangerous-findings')}
                                    </Button>
                                    <Button
                                        variant="outline"
                                        className={`${classes.navButton} dangerous`}
                                        type="submit"
                                        onClick={() => {
                                            setIsDangerous(true);
                                        }}
                                    >
                                        {t('Audit.layout.L4.next-finding-dangerous-findings')}
                                    </Button>
                                </Box>
                            </>
                        ) : (
                            <Box sx={{ display: 'flex' }}>
                                {!takingPicture && !uploadingFiles && (
                                    <>
                                        <Button
                                            variant="outline"
                                            className={classes.navButton}
                                            onClick={() => setOpenAlert(true)}
                                        >
                                            {t('Storno')}
                                        </Button>
                                        <Button
                                            variant="outline"
                                            className={classes.navButton}
                                            type="submit"
                                        >
                                            {t('Confirm')}
                                        </Button>
                                    </>
                                )}
                            </Box>
                        )}
                    </Footer>

                    <DescriptionModal
                        text={modalText}
                        open={openDescription}
                        onClose={() => handleCloseModal()}
                    />
                </Form>
            )}
        </Formik>
    );
};
