import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-notifications/lib/notifications.css';
import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/style.scss';
import { App } from './App';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(
    'c868074f0991672b621730b712d373b4Tz0xMDQyNjUsRT0xNzY1NzA4MjAyMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI='
);

ReactDOM.render(<App />, document.getElementById('root'));
//registerServiceWorker();
