import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { AuditLayoutId } from '../../../types/AuditMobile';
import { AuditTypeSurvey } from '../../../types/AuditTypeApiResponse';
import { AuditLayout_1 } from '../Layout_1/AuditLayout_1';
import { AuditLayout_2 } from '../Layout_2/AuditLayout_2';
import { AuditLayout_4 } from '../Layout_4/AuditLayout_4';

interface StateProps {
    state: {
        auditType: AuditTypeSurvey;
    };
}

/**
 * Audit zone router - zobrazí layout komponentu podle layoutId
 * @returns
 */
export const AuditLayoutRouter = () => {
    const { state: location }: { state: StateProps } = useLocation();
    const { auditType } = location.state;

    switch (auditType.layoutId) {
        case AuditLayoutId.Layout1:
            return <AuditLayout_1 auditType={auditType} />;
        case AuditLayoutId.Layout2:
            return <AuditLayout_2 auditType={auditType} />;
        case AuditLayoutId.Layout3:
            // pro L3 je obrazovka stejná jako L2
            return <AuditLayout_2 auditType={auditType} />;
        case AuditLayoutId.Layout4:
            return <AuditLayout_4 auditType={auditType} />;
        case AuditLayoutId.Layout5:
            return <AuditLayout_2 auditType={auditType} hideLevel />;
        case AuditLayoutId.Layout6:
            // pro L6 je obrazovka stejná jako L2
            return <AuditLayout_2 auditType={auditType} hideLevel />;
        default:
            break;
    }
};
