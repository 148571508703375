export interface ItemWithId {
    id: number;
    [key: string]: string | number | boolean | null;
}

export const API_URL = {
    LOGIN: '/api/User/LogIn',
    LOGOUT: '/api/User/LogOut',
    GET_CURRENT_USER: '/api/User/GetCurrentUser',
    IS_AUTHENTICATED: '/api/User/IsAuthenticated',
    GET_CURRENT_LANGUAGE: '/api/Language/GetCurrentLanguage',
    GET_TRANSLATIONS: '/api/Language/GetTranslations',
    USER_SEARCH_BY_NAME: '/api/User/SearchByName',
    USER_SEARCH_BY_ID: 'api/User/SearchById',
    USER_GETLANGUAGE: 'api/User/GetCurrentLanguage',
    USER_SETLANGUAGE: 'api/User/SetLanguage',
    USER_GETCURRENTUSER: 'api/User/GetCurrentUser',

    LANGUAGE_LIST: 'api/Language/GetList',
    LANGUAGE_ITEM: 'api/Language/GetItem',
    LANGUAGE_CREATE: 'api/Language/CreateItem',
    LANGUAGE_UPDATE: 'api/Language/UpdateItem',
    LANGUAGE_DELETE: 'api/Language/DeleteItem',

    LANGUAGE_TEXT_LIST: 'api/LanguageText/GetList',
    LANGUAGE_TEXT_UPDATE: 'api/LanguageText/UpdateItem',

    LOCAL_USER_IS_ENABLED: '/api/LocalUser/FeatureIsEnabled',
    LOCAL_USER_LIST: '/api/LocalUser/GetList',
    LOCAL_USER_ITEM: '/api/LocalUser/GetItem',
    LOCAL_USER_CREATE: '/api/LocalUser/CreateItem',
    LOCAL_USER_UPDATE: '/api/LocalUser/UpdateItem',
    LOCAL_USER_UPDATE_PASSWORD: '/api/LocalUser/UpdatePassword',
    LOCAL_USER_DELETE: '/api/LocalUser/DeleteItem',

    ROLE_LIST: 'api/Role/GetList',
    PERMISSION_LIST: '/api/Permission/GetList',
    PERMISSION_ITEM: '/api/Permission/GetItem',
    PERMISSION_POST: '/api/Permission/CreateItem',
    PERMISSION_DELETE: '/api/Permission/DeleteItem',
    PERMISSION_UPDATE: '/api/Permission/UpdateItem',
    KPIITEMMEMBER_LIST: '/api/KpiItemMember/GetList',
    VSM_LIST: '/api/Vsms/GetList',
    VSM_LIST_FILTERED_BY_AUDIT_TYPE: '/api/Vsms/GetListFilteredByAuditType',
    VSM_ITEM: '/api/Vsms/GetItem',
    VSM_DELETE: '/api/Vsms/DeleteItem',
    VSM_CREATE: '/api/Vsms/CreateItem',
    VSM_UPDATE: '/api/Vsms/UpdateItem',
    PRODUCTION_LIST: '/api/Productions/GetList',
    PRODUCTION_LIST_FILTERED_BY_AUDIT_TYPE: '/api/Productions/GetListFilteredByAuditType',
    PRODUCTION_DELETE: '/api/Productions/DeleteItem',
    PRODUCTION_CREATE: '/api/Productions/CreateItem',
    PRODUCTION_UPDATE: '/api/Productions/UpdateItem',
    PRODUCTION_ITEM: '/api/Productions/GetItem',
    PRODUCTION_REPORT: '/api/Productions/GetReport',
    ZONES_LIST: '/api/Zones/GetList',
    ZONES_LIST_FILTERED_BY_AUDIT_TYPE: '/api/Zones/GetListFilteredByAuditType',
    ZONES_ITEM: '/api/Zones/GetItem',
    ZONES_CREATE: '/api/Zones/CreateItem',
    ZONES_DELETE: '/api/Zones/DeleteItem',
    ZONES_UPDATE: '/api/Zones/UpdateItem',
    ZONE_LEADER_LIST: '/api/ZoneLeader/GetList',
    ZONE_LEADER_DELETE: '/api/ZoneLeader/DeleteItem',
    ZONE_LEADER_ITEM: '/api/ZoneLeader/GetItem',
    ZONE_LEADER_CREATE: '/api/ZoneLeader/CreateItem',
    ZONE_LEADER_UPDATE: '/api/ZoneLeader/UpdateItem',
    AUDIT_LIST: '/api/Audit/GetList',
    AUDIT_DELETE: '/api/Audit/DeleteItem',
    AUDIT_ITEM: '/api/Audit/GetItem',
    AUDIT_CREATE: '/api/Audit/CreateItem',
    AUDIT_UPDATE: '/api/Audit/UpdateItem',
    AUDIT_LIST_FOR_USER: '/api/Audit/GetAuditsForUser',
    AUDIT_GET_RANDOM_GROUP: '/api/Audit/GetRandomGroupForUser',
    AUDITOR_LIST: '/api/Auditor/GetList',
    AUDITOR_ITEM: '/api/Auditor/GetItem',
    AUDITOR_CREATE: '/api/Auditor/CreateItem',
    AUDITOR_UPDATE: '/api/Auditor/UpdateItem',
    AUDITOR_DELETE: '/api/Auditor/DeleteItem',
    AUDIT_TYPE_LIST: '/api/AuditType/GetList',
    AUDIT_TYPE_DELETE: '/api/AuditType/DeleteItem',
    AUDIT_TYPE_ITEM: '/api/AuditType/GetItem',
    AUDIT_TYPE_CREATE: '/api/AuditType/CreateItem',
    AUDIT_TYPE_UPDATE: '/api/AuditType/UpdateItem',
    AUDIT_TYPE_ALLOWED_AUDITS: '/api/AuditType/GetAllowedAudits',
    AUDIT_TYPE_LAYOUT_LIST: '/api/AuditTypeLayout/GetList',
    AUDIT_TYPE_LAYOUT_DELETE: '/api/AuditTypeLayout/DeleteItem',
    AUDIT_TYPE_LAYOUT_ITEM: '/api/AuditTypeLayout/GetItem',
    AUDIT_TYPE_LAYOUT_CREATE: '/api/AuditTypeLayout/CreateItem',
    AUDIT_TYPE_LAYOUT_UPDATE: '/api/AuditTypeLayout/UpdateItem',
    AUDIT_QUESTION_LIST: '/api/AuditQuestion/GetList',
    AUDIT_QUESTION_DELETE: '/api/AuditQuestion/DeleteItem',
    AUDIT_QUESTION_CREATE: '/api/AuditQuestion/CreateItem',
    AUDIT_QUESTION_UPDATE: '/api/AuditQuestion/UpdateItem',
    AUDIT_QUESTION_ITEM: '/api/AuditQuestion/GetItem',
    AUDIT_QUESTION_UPDATE_ORDERING: '/api/AuditQuestion/UpdateOrdering',
    AUDIT_QUESTION_ANSWER_CREATE: '/api/AuditQuestionAnswer/CreateItem',
    AUDIT_QUESTION_ANSWER_UPDATE: '/api/AuditQuestionAnswer/UpdateItem',
    AUDIT_QUESTION_ANSWER_DELETE: '/api/AuditQuestionAnswer/DeleteItem',
    AUDIT_QUESTION_ANSWER_LIST: '/api/AuditQuestionAnswer/GetList',
    AUDIT_QUESTION_ANSWER_ITEM: '/api/AuditQuestionAnswer/GetItem',
    AUDIT_QUESTION_TYPE_LIST: '/api/QuestionType/GetList',
    AUDIT_QUESTION_TYPE_DELETE: '/api/QuestionType/DeleteItem',
    AUDIT_QUESTION_TYPE_CREATE: '/api/QuestionType/CreateItem',
    AUDIT_QUESTION_TYPE_UPDATE: '/api/QuestionType/UpdateItem',
    AUDIT_QUESTION_TYPE_ITEM: '/api/QuestionType/GetItem',

    GET_REPORT_L3: '/api/Report/GetReportForLayout3',
    GET_REPORT_VSM_EVALUATION: '/api/Report/GetVsmEvaluationOverview',
    GET_REPORT_PRODUCTION_EVALUATION: '/api/Report/GetProductionEvaluationOverview',
    GET_REPORT_ZONE_EVALUATION: '/api/Report/GetZoneEvaluationOverview',

    COMMON_APP_INFO: '/api/Common/AppInfo',
    COMMON_SAFETY_IMPACTS_LIST: '/api/Common/GetSafetyImpacts',
    COMMON_OBSERVED_ACTIVITIES_INTERNAL_LIST: '/api/Common/GetObservedActivitiesInternal',
    COMMON_OBSERVED_ACTIVITIES_EXTERNAL_LIST: '/api/Common/GetObservedActivitiesExternal',
    COMMON_GET_EDOCUMENTS_URL: '/api/Common/GetEDocumentsUrl',
    COPY_ZONE: '/api/Zones/CopyZoneData',
    GET_RANDOM_ZONE: '/api/Zones/GetRandomZone',
    CHECK_LIST_ATTACHMENT_GET: '/api/ChecklistAnswerAttachment/Get',

    USER_TASK_GET_LIST_FILTERED: '/api/UserTask/GetListFiltered',
    USER_TASK_CREATE: '/api/UserTask/CreateItem',
    USER_TASK_UPDATE: '/api/UserTask/UpdateItem',
    USER_TASK_DELETE: '/api/UserTask/DeleteItem',
    USER_TASK_GET_LIST: '/api/UserTask/GetList',
    USER_TASK_GET_ITEM: '/api/UserTask/GetItem',
    USER_TASK_ADD_COMMENT: '/api/UserTask/AddComment',
    USER_TASK_GET_ALLOWED_ACTIONS: '/api/UserTask/GetAllowedActions',
    USER_TASK_ASSIGN: '/api/UserTask/Assign',
    USER_TASK_SET_PLANNED_DATE: '/api/UserTask/SetPlannedDate',
    USER_TASK_FINISH: '/api/UserTask/Finish',
    USER_TASK_REOPEN: '/api/UserTask/Reopen',
    USER_TASK_TAKE: '/api/UserTask/Take',
    USER_TASK_DENY: '/api/UserTask/Deny',
    USER_TASK_UPDATE_SAFETY_IMPACT: '/api/UserTask/UpdateSafetyImpact',
    USER_TASK_CHANGE_ZONE: '/api/UserTask/ChangeZone',
    USER_TASK_GET_CREATOR_NET_ID: '/api/UserTask/GetCreatorNetId',

    USER_TASK_ACTION_CREATE: '/api/UserTaskAction/CreateItem',
    USER_TASK_ACTION_UPDATE: '/api/UserTaskAction/UpdateItem',
    USER_TASK_ACTION_DELETE: '/api/UserTaskAction/DeleteItem',
    USER_TASK_ACTION_GET_LIST: '/api/UserTaskAction/GetList',
    USER_TASK_ACTION_GET_ITEM: '/api/UserTaskAction/GetItem',

    USER_TASK_ATTACHMENT_GET_LIST: '/api/UserTaskAttachment/GetList',
    USER_TASK_ATTACHMENT_GET: '/api/UserTaskAttachment/Get',
    USER_TASK_ATTACHMENT_UPLOAD: '/api/UserTaskAttachment/Upload',

    CHECK_LIST_ANSWER_GET_ITEM: '/api/ChecklistAnswer/GetItem',
    CHECK_LIST_ANSWER_GET_LIST: '/api/ChecklistAnswer/GetList',
    CHECK_LIST_ANSWER_DELETE: '/api/ChecklistAnswer/DeleteItem',
    CHECK_LIST_ANSWER_UPDATE: '/api/ChecklistAnswer/UpdateItem',
    CHECK_LIST_ANSWER_CREATE: '/api/ChecklistAnswer/CreateItem',

    CHECK_LIST_ANSWER_ATTACHMENT_GET_LIST: '/api/ChecklistAnswerAttachment/GetList',
    CHECK_LIST_ANSWER_ATTACHMENT_GET: '/api/ChecklistAnswerAttachment/Get',
    CHECK_LIST_ANSWER_ATTACHMENT_UPLOAD: '/api/ChecklistAnswerAttachment/Upload',

    CHECK_LIST_CREATE: '/api/Checklist/CreateItem',
    CHECK_LIST_IS_FINISHED: '/api/Checklist/ChecklistIsFinished',
    CHECK_LIST_IS_FINISHED_LAYOUT_6: '/api/Checklist/ChecklistIsFinishedLayout6',
};
